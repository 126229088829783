
var Resizer = {

    isDesktop: true,

    init: function() {

        // Prvi klic - pred resizi
        Resizer.responsive();

        $(window).resize(function() {
            Resizer.responsive();
        });
    },

    /**
     * V primeru, da je širina okna manjša/večja od 1023, dodaj/odstrani ustrezne klase
     */
    responsive: function() {
        var $body = $(document.body),
            $mainMenu = $body.find('.js-menuclass');

        if (window.matchMedia('(max-width: 1220px)').matches) {
            $mainMenu.addClass('fixed-menu');
            $body.addClass('responsive');
            $('.main-side').removeClass('sticky');
            this.isDesktop = false;

            StickyNav.permanentActive = false;
            StickyNav.active = false;
        } else {
            $mainMenu.removeClass('fixed-menu');
            $body.removeClass('responsive');
            this.isDesktop = true;

            $('.main-side').addClass('sticky');
        }
    },

    /**
     * Pridobi višino vgnezdenih elementov
     * @param  selector holder
     * @param  selector target
     * @return array
     */
    getMaxHeights: function ($holder, target) {
        var maxHeights = [];

        // Če holder obstaja
        if ($holder.length) {
            $holder.each(function(holderIndex, holderEl) {
                // Pridobi child elemente
                var holderChildren = $(holderEl).children(target);
                $(holderChildren).each(function(childrenIndex, el) {
                    var $element = $(el);
                    if (Hlp.isNoE(maxHeights[childrenIndex])) {
                        // Index še nima spravljene nobene višine childa
                        maxHeights[childrenIndex] = Math.ceil(el.getBoundingClientRect().height + 1);
                    } else if (maxHeights[childrenIndex] < Math.ceil(el.getBoundingClientRect().height)) {
                        // Višina childa je manjša od najvišje zapisane višine
                        maxHeights[childrenIndex] = Math.ceil(el.getBoundingClientRect().height + 1);
                    }
                });
            });
        }
        return maxHeights;
    },

    /**
     * Poravnaj višino večih vgnezdenih elementov
     * @param  selector 	holder  	Element, ki drži ciljane elemente
     * @param  selector 	target 		Ciljani element
     * @param  selector 	parent 		Parent element, ki omejuje izbiro
     * @return array
     */
    equalHeightsMultiple: function(holder, target, parent) {
        var	$parent;

        // target/child selektor ni določen. Vzamemo vse childe holder-ja
        if (typeof(target) === 'undefined') target = '';

        if (typeof(parent) !== 'undefined') {
            $parent = $(parent);
            holder = $parent.find(holder);
        } else {
            holder = $(document.body).find(holder);
        }
        if (typeof(parent) !== 'undefined' && $parent.hasClass('align-limiter')) {
            holder.each(function(index, val) {
                Resizer.equalHeightsMultipleRecursive($(val), target, $parent);
            });
        } else {
            Resizer.equalHeightsMultipleRecursive($(holder), target);
        }
    },

    equalHeightsMultipleRecursive: function($holder, target) {
        var maxHeights;

        if ($holder.length) {
            // Pridobi maksimalne višine elementov
            maxHeights = Resizer.getMaxHeights($holder, target);
            $holder.each(function(holderIndex, holderEl) {
                // Pridobi child elemente, ki se ujemajo s selektorjem
                var holderChildren = $(holderEl).children(target);
                if (holderChildren.length) {
                    $(holderChildren).each(function(childrenIndex, el) {
                        var $element = $(el);
                        if (Hlp.isNoE(maxHeights[childrenIndex])) {
                        // Višina za child-a ni zapisana v arrayu
                            return false;
                        } else if (maxHeights[childrenIndex] !== el.getBoundingClientRect().height) {
                        // Če child ni najvišji, mu spremeni višino
                            $element.css('min-height', Math.ceil(maxHeights[childrenIndex]));
                        }
                    });
                }
            });
        }
    },

    /**
     * Poravnaj višino elementov
     * @param  selector group       	Ciljani element
     * @param  int itemsPerRow 			Število elementov v vrsti
     */
    equalHeights: function(group, itemsPerRow) {
        var rowItems;
        var tallest = 0;
        var rows = Math.ceil(group.length / itemsPerRow);
        group = $(group);
        for (var i = 1; i <= rows; i++) {
            tallest = 0;
            if (i == 1) {
                rowItems = group.filter(':lt(' + i * itemsPerRow + ')');
            } else {
                rowItems = group.filter(':lt(' + i * itemsPerRow + ')').filter(':gt(' + ((i * itemsPerRow) - (itemsPerRow + 1)) + ')');
            }

            for (var i2 = rowItems.length - 1; i2 >= 0; i2--) {
                var thisHeight = Math.ceil(rowItems[i2].getBoundingClientRect().height);
                if (thisHeight > tallest) {
                    tallest = thisHeight;
                }

            }
            rowItems.height(tallest);
        }
    },

    /**
     * Prilagodi višino elementa glede na višino parent elementa
     * @param  {string} element
     */
    maxHeight: function (element) {
        var $target;
        $target = $(element);
            $target.each( function(key, singleTarget) {
                Resizer.adjustMinHeight(singleTarget);
            });

        $target.removeClass('hidden');
    },

    adjustMinHeight: function (singleTarget) {
        var $singleTarget = $(singleTarget);
        $singleTarget.css({'height' : 'auto'});
        $singleTarget.css({'min-height' : 'auto'});
        $singleTarget.parent().css({'height' : 'auto'});
        $singleTarget.parent().css({'min-height' : 'auto'});
        $singleTarget.css('min-height', $singleTarget.parent().height());
    },


    /**
     * Sredinska poravnava absolutno pozicioniranega elementa
     * @param  {[type]} element [description]
     * @return {[type]}         [description]
     */
    alignCenterHeight: function (element)  {
        var target;
        var targetHeight;
        var targetMargin;

        target = $(element);
        targetHeight = target.height();
        targetMargin = Math.ceil(targetHeight / 2);
        target.css({
            'position': 'absolute',
            'top': '50%',
            'margin-top': -targetMargin,
        }).removeClass('invisible');
    },


    stickyRecalc: function (event) {
        if (! Resizer.isDesktop) {
            $element.trigger("sticky_kit:detach");
            return false;
        }

        var $element = $(this);
        if($element.is(document) || typeof $element.get(0).nodeName == 'undefined') {
            $element = $('.sticky-cart');
        }
        if(!$element.hasClass('js-checkoutcart')) {
            Resizer.maxHeight($element.parent());
        }
        $element.trigger("sticky_kit:detach");
        if (! $element.hasClass('stuck')) {
            $element.stick_in_parent();
        }
        $element.trigger("sticky_kit:recalc");

    }
};

