
	/* return functions which are meant to be used outside of IIFE scope */
    return {
        Hlp : Hlp,
        Cart : Cart,
        Resizer : Resizer,
        StickyNav : StickyNav,
        Newsletter : Newsletter,
        AdditionalTriggers : AdditionalTriggers,
        Utility: Utility,
        Tracking: Tracking,
        Wordpress: Wordpress,
        DomainCheck: DomainCheck,
    };
/* jshint ignore:start */
}));
/* jshint ignore:end */
