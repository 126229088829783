var StickyNav = {
    active: true,
    permanentActive: true,
    lastScrollTop: 0,
    scrolls: 0,

    init: function () {

        $(window).scroll(function (event) {
            if (StickyNav.isEnabled()) {

                var st = $(this).scrollTop(),
                    $mainHeader = $('#main-header'),
                    $topHeader = $('.top-header'),
                    $topMenu = $('.top-menu');

                if (st > StickyNav.lastScrollTop){
                    /* Scrollanje navzdol */
                    if ($mainHeader.hasClass('scrolled')) {
                        StickyNav.hide();

                        setTimeout(function () {
                        }, 400);
                    }
                } else if (st < StickyNav.lastScrollTop ) {
                    /* Scrollanje navzgor */
                    StickyNav.scrolls++;
                    var navigationOffsetTop = $('.top-keyvisual').length ? ($topMenu.height() + $topHeader.height()) : $mainHeader.height();
                    var isDistance = ($(window).scrollTop() > navigationOffsetTop);
                    if (isDistance && (StickyNav.scrolls === 2 || StickyNav.lastScrollTop - st > 100)) {
                        // Viewport je nižji od navigationOffsetTop -> prikaži stickynav && gre za 2 scrolla oz razdaljo večjo od 100 (tablice )
                        if ($mainHeader.hasClass('scrolled') === false) {
                            StickyNav.show();
                        }
                        StickyNav.scrolls = 0;
                    } else if (isDistance === false) {
                        StickyNav.hide();
                        StickyNav.scrolls = 0;
                    }
                }
                StickyNav.lastScrollTop = st;
            }

        });
    },

    show : function() {
        if (! StickyNav.isEnabled()) {
            return false;
        }

        var $mainHeader = $('#main-header'),
            $topMenu = $('.top-menu'),
            filler = '<div class="padding-fill"></div>';
        // Če gre za prvi scroll, prepreči skakanje elementov s paddingom
        $topMenu.prepend(filler).css('padding-top', $topMenu.height());
        $mainHeader.addClass('scrolled animate show');
    },

    hide : function() {
        if (! StickyNav.isEnabled()) {
            return false;
        }

        var $mainHeader = $('#main-header'),
            $topMenu = $('.top-menu');

        if ($mainHeader.hasClass('scrolled animate show')) {
            $mainHeader.removeClass('scrolled animate show');
            $topMenu.css('padding-top', 0);
        }
    },

    isEnabled : function () {
        return (StickyNav.active && StickyNav.permanentActive);
    }
};

