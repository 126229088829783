
var Newsletter = {

	before: '.newsletter-before',
	success: '.newsletter-after',


	handleAjaxResponse: function (event, data) {
        var form = event.target;
        var button = $(form).find(':submit');

        var formDetails = {form_id: form.id, form_name: form.name, form_destination: form.action, form_submit_text: button.text()};

		if (data.status == 200) {
            Tracking.track({ eventCategory: 'Newsletter', eventAction: 'SentForm', eventLabel: 'NewsletterSuccess'}, formDetails);

			$(Newsletter.before).fadeOut('fast');
			$(Newsletter.success).removeClass('hidden').hide().fadeIn('fast');
			Cookies.set('newsletter', true, { expires: data.expires_in / 86400 });
		} else {
			var $notice = $('.newsletter-notice');
			$('.notice:not(.newsletter-notice)').empty().remove();
			$.each(data.responseJSON, function (key, value) {
				var $newNotice = $notice.clone().removeClass('newsletter-notice hidden').hide().fadeIn('fast');
				$newNotice.find('.warning').text(value);
				$newNotice.appendTo($notice.parent());
			});

		}

	},



};

