
var Utility = {
    init: function() {
        // TLD podstran - mini cenik
        $('.price-list').on('change click', '.js-tldprice-switch', Utility.changeTldPrice);
        $('.price-list').on('click', '.to-check', Utility.jumpToDomainCheck);
        // Radio
        $(document).on('click', '.js-radio', Utility.radioSwitch);

        // Simple tabs
        $('.js-simpletab').on('click', Utility.simpleTabs);
        Utility.faqHashCheck();

        // Paginacija
        $('.append-parameter').on('click', Utility.appendParametersAndRefresh);
    },

	faqHashCheck: function(event) {
		var hash = window.location.hash;
		if(!hash || hash.indexOf('faq') === -1) {
			return false;
		}

		$(hash).addClass('active');
	},


    /**
     * Simple tabs switch content and set 'active' class
     * @param event
     */
	simpleTabs: function(event) {
		event.preventDefault();
        var $this = $(this),
            target = $this.data('tabs-target'),
            $tabsContent = $('.js-simpletabs-content');

        if (! $this.hasClass('js-radio')) {
            $this.addClass('active').siblings('.js-simpletab').removeClass('active');
        }

        $tabsContent.children(':not('+target+')').hide('100');
		if ($(target).is(':hidden')) {
            $tabsContent.children(target).show('100');
		}
	},


    /**
     * Toggle radio button
     * Toggles '.active' class and property 'checked'
     */
	radioSwitch: function(event) {
        event.preventDefault();
        var $this = $(this);
        var $siblings;
        var $radioHodler = $this.closest('.js-radiohodler');

        if ($radioHodler.length) {
            $siblings = $radioHodler.find('.js-radio');
        } else {
            $siblings = $this.siblings('.js-radio');
        }
        $siblings.removeClass('active').children('input[type=radio]:checked').prop('checked', false);

        // active style
        $this.addClass('active');

		// radio button
		$this.children('input[type=radio]').prop('checked', true).trigger('change');
	},

	appendParametersAndRefresh: function(event) {
	    Debug.log('Utility.appendParametersAndRefresh');
        event.preventDefault();
        var parameters = $(this).data('append-object');
        if (typeof parameters !== 'undefined') {
            Hlp.appendParametersToUrl(parameters);
            window.location.href = window.location.href;
        }
	},

	windowPopup: function(url, width, height) {
		// Poravnava popup-a
		var left = (screen.width / 2) - (width / 2),
		top = (screen.height / 2) - (height / 2);

		window.open(
			url,
			"",
			"menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",centerscreen=yes,chrome=yes,top=" + top + ",left=" + left
		);
	},

	changeTldPrice: function(event) {

		var el = $(this);
		var node = el[0].nodeName;
		if(node == 'A') {
			event.preventDefault();
			el.closest('li').siblings().removeClass('active');
			el.closest('li').addClass('active');
		}

		var service = $('input.js-tldprice-switch:checked').val();
		var dur = $('.active a.js-tldprice-switch').text();

		$('.tld-price').hide();
		$('.tld-price[data-pricefor=' + service + '-' + dur + ']').removeClass('hidden').fadeIn();
	},

	jumpToDomainCheck: function(event) {
		event.preventDefault();
		var $this = $(this);
		$('html, body').animate({
	        scrollTop: $($this.attr('href')).offset().top
	    }, 500, function() {
			$('.content .domain-check input').focus().velocity(
				{'backgroundColor' : '#FFF69A'},
				{ duration : 200,
					complete : function() {
						$('.content .domain-check input').velocity({'backgroundColor' : '#ffffff'});
					}
				}
			);
        });
	},

	startTimer: function (duration, display, callback) {
	    var timer = duration, seconds;
	    setInterval(function () {
	        seconds = parseInt(timer % 60, 10);

	        display.text(seconds);

	        if (--timer < 0) {
	            timer = duration;
	        }
	        if (timer == duration) {
	        	callback();
	        }
	    }, 1000);
	},


    selecttext: function(text) {
        text = text.get(0);
        var doc = document,
            range,
            selection;

        if (doc.body.createTextRange) {
            range = document.body.createTextRange();
            range.moveToElementText(text);
            range.select();
        } else if (window.getSelection) {
            selection = window.getSelection();
            range = document.createRange();
            range.selectNodeContents(text);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    },

	/**
	 * Call function by string
	 * @param  {object} o
	 * @param  {string} s
	 * @return {function}
	 */
	callByString: function(o, s) {
	    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
	    s = s.replace(/^\./, '');           // strip a leading dot
	    var a = s.split('.');
	    for (var i = 0, n = a.length; i < n; ++i) {
	        var k = a[i];
	        if (k in o) {
	            o = o[k];
	        } else {
	            return;
	        }
	    }
	    return o;
	},

	/**
	 * Call function via string
	 * AKA when I grow up I wanna be eval()
	 * @param  {string} callbackString NS.Utility.sample
	 * @param  {array,string,object} arg $this
	 * @return {function}
	 */
	callByStringNested: function(callbackString) {
		var pureCallback,
			cleanCallback = window;


		pureCallback = callbackString.split('.');
		pureCallback.every( function(element, index, array) {
			if (typeof cleanCallback[element] !== 'undefined') {
				cleanCallback = cleanCallback[element];
			} else {
				return false;
			}
			return true;
		});

		return cleanCallback;
	},

	/**
	 * Prevent bots from sending forms
	 * @param  event
	 * @return void
	 */
	protectedFormSubmit: function(event) {
		var $this,
			$parent;

		event.preventDefault();

		$this = $(this);
		$parent = $this.closest('form');

		if ($parent.data('action')) {
			$parent.attr('action', $this.data('action')).submit();
		}
	}

};
