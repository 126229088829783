var Debug = {
	timerStart: 0,
	output: "",

	log: function (str) {
		if (debugMode) {
			console.info(str);
		}
	},
	begin: function (str) {
		if (debugMode) {
			if (typeof  performance !==  'undefined' && typeof performance.now === "function") {
					this.timerStart = performance.now();
			}
			this.output = str + ": ";
		}
	},
	end: function (str) {
		if (debugMode) {
			if (this.timerStart) {
				if (typeof  performance !==  'undefined' && typeof performance.now === "function") {
					this.output += performance.now()-this.timerStart;
				}
			} else {
				this.output = str + " - no start timer"; // preventiva
			}
			
			console.log(this.output);
		}
	}
};

