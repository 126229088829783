var is_opera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
var is_Edge = navigator.userAgent.indexOf("Edge") > -1;
var is_chrome = !!window.chrome && !is_opera && !is_Edge;
var is_explorer= typeof document !== 'undefined' && !!document.documentMode && !is_Edge;
var is_firefox = typeof window.InstallTrigger !== 'undefined';
var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

if(is_explorer || is_safari){
	$(".img-stevec").css("display", "block");
	$(".stevec").css("display", "none");
}


$.fn.isOnScreen = function(){

	var win = $(window);

	var viewport = {
		top : win.scrollTop(),
		left : win.scrollLeft()
	};
	viewport.right = viewport.left + win.width();
	viewport.bottom = viewport.top + win.height();

	var bounds = this.offset();

    if (Hlp.isNoE(bounds)) {
        return false;
    }

	bounds.right = bounds.left + this.outerWidth();
	bounds.bottom = bounds.top + this.outerHeight();

	return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));

};
var r = 70;
var cf = 2 * Math.PI * r;
var semi_cf = cf / 2;
var semi_cf_1by3 = semi_cf / 3;
var semi_cf_2by3 = semi_cf_1by3 * 2;
var meter_dimension = (r * 2) + 100;

/* changing variables */

var circles = $(document).find(".circle");
var total_circles = circles.length;
var wrapperAll = $(document).find(".wrapper");
/* nastavljanje atributa 'stroke-dasharray' na vsakega */
var maskAll = $(document).find(".mask");
var outline_endsAll = $(document).find(".outline_ends");
var lowAll = $(document).find(".low");
var outline_curvesAll = $(document).find(".outline_curves");
/*  end */
var meter_needleAll =  $(document).find(".meter_needle");

var mask2All = $(document).find(".mask");
var sliderAll = $(document).find(".slider");
var total_stevci=$(document).find(".stevec");

var wrapper, slider, mask2, meter_needle, endSliderValue, percent, meter_value, outline_curves, low, outline_ends, mask, slajd;
var vidno=false;

for (var i = 0; i < total_circles; i++) {
		/* nastavimo polmer kroga  */
		$(circles[i]).attr('r', r);
	}
function animateSlide(slider, slajd, mask2, meter_needle, endSliderValue){
	setInterval(function() { // this code is executed every 25 milliseconds:
		if(slajd<endSliderValue){
				$(slider).attr("value", slajd);
				var percent = $(slider).val();
				var meter_value = semi_cf - ((percent * semi_cf) / 100);
				$(mask2).attr("stroke-dasharray", meter_value + "," + cf);
				$(meter_needle).css("transform","rotate(" + (270 + ((percent * 180) / 100)) + "deg)");
			slajd++;
		}
		else{
			return;
		}
	}, 25);
}
if(!is_explorer && !is_safari){
	$(".img-stevec").css("display", "none");
	$(".stevec").css("display", "block");

    $(window).on('scroll', function(){
        if($('.stevec').isOnScreen()==true &&vidno==false){
            for(var j=0;j<total_circles;j++){
                wrapper = wrapperAll[j];
                $(wrapper).css("width", meter_dimension + "px");
                $(wrapper).css("height", meter_dimension + "px");
                $(wrapper).css("margin-bottom", -80 + "px");

                outline_curves=outline_curvesAll[j];
                $(outline_curves).attr("stroke-dasharray", semi_cf + "," + cf);

                low=lowAll[j];
                $(low).attr("stroke-dasharray", semi_cf + "," + cf);

                outline_ends=outline_endsAll[j];
                $(outline_ends).attr("stroke-dasharray", 2 + "," + (semi_cf - 2));

                mask=maskAll[j];
                $(mask).attr("stroke-dasharray", semi_cf + "," + cf);

                slider = sliderAll[j];
                mask2 = mask2All[j];
                meter_needle =  meter_needleAll[j];
                endSliderValue=$(slider).attr("endvalue");
            slajd=0;
            $(animateSlide(slider, slajd, mask2, meter_needle, endSliderValue));
        }
            vidno=true;
            return ;
        }
    });
}

