    /**
 * Tooltip
 */
var Tooltip = {

    predefinedOptions : {
        simple : {
            hide : {
                event: 'mouseout',
                delay: 500,
                fixed: true,
            },
            show : {
                event: 'click mouseenter mouseover',
                delay: 50
            },
        },
        advanced : {
            hide : {
                event: false,
                delay: 50,
            },
        }
    },


    init: function(parent) {
        if (typeof parent === 'undefined') {
            parent = document;
        }

        // Close button
        $(parent).on('click', '.qtip .icon-close', function() {
            $(this).closest('.qtip').qtip('hide');
        });

        $(document.body).on('modal--open', Tooltip.closeAll());

        // Bind on .popover
        $('.popover', parent).unbind();
        $('.popover', parent).each(function() {
            // @TODO - data-qtip-trigger
            var selectedOptions = 'simple',
                $this = $(this),
                qtipOptions;

            // Get tooltip options object
            qtipOptions = Tooltip.getOptions(selectedOptions, $this);

            // Init tooltip
            if (($.isFunction($.fn.qtip))) {
                $this.qtip(qtipOptions, $this);
            }
        });
    },

    closeAll: function() {
        $('div.qtip:visible').qtip('hide');
    },

    getOptions: function(selectedOptions, $element) {
        var classes = $element.data('qtipClasses'),
            ajaxTarget = $element.data('qtipAjax'),
            ajaxData = $element.data('qtipAjaxData'),
            content = $($element.data('qtipContent')).html();

        // Check if it's ajax tooltip
        if (ajaxTarget) {
            content =   '<div class="popover-box tooltip bg-dgrey"><div class="popover-header">'+
                            '<h6 id="tooltip-info">Nalaganje vsebine</h6><i class="icon-close"></i>'+
                        '</div><div class="holder text-center"><i class="btn-loading f-huge"></i></div></div>';
        }

        // Qtip 'default' options
        var qtipOptions = {
            prerender: false, // Render tooltip HTML on $(document).ready(
            metadata: {
                type: 'html5', // Use HTML5 data-* attributes
                name: 'qtipopts' // Grab the metadata from the data-qtipOpts HTML5 attribute
            },
            position: {
                my: 'bottom center',  // Position my top left...
                at: 'top center', // at the bottom right of...
                target: $element,
                container: $(document.body), // Defaults to $(document.body)
                viewport: $(window), // Requires Viewport plugin
                adjust: {
                    x: 0, y: -13, // Minor x/y adjustments
                    mouse: true, // Follow mouse when using target:'mouse'
                    scroll: false, // Reposition on resize by default
                    resize: true, // Reposition on resize by default
                    method: 'shift flipinvert' // Requires Viewport plugin
                },
                effect: function(api, pos, viewport) {
                    $element.animate(pos, {
                        duration: 200,
                        queue: false
                    });
                },
            },
            style: {
                classes: classes,
                widget: false, // Not a jQuery UI widget
                width: false, // No set width
                height: false, // No set height
            },
            show: {
                target: $element,
                event: 'click mouseenter'
            },
            hide: {
                event: false,
                delay: 50,
            },

            content: {
                text: function(event, api) {
                    if (ajaxTarget) {
                        $.ajax({
                            url: ajaxTarget,
                            data: ajaxData,
                            'dataType': 'text',
                        }).done(function(html) {
                            api.set('content.text', html);
                            api.reposition(event, false);
                        }).fail(function(xhr, status, error) {
                            content = $($.parseHTML(content)).html(status + ': ' + error);
                            api.set('content.text', content);
                            api.reposition(event, false);
                        });
                        //Tracking.track({ eventCategory: 'Tooltip', eventAction: 'Trigger', eventLabel: ajaxTarget});
                    } else {
                        //Tracking.track({ eventCategory: 'Tooltip', eventAction: 'Trigger', eventLabel: event.currentTarget.className});
                    }
                    return content;
                },
            },
            events: {
                move: function(event, api) {
                    var tooltipSelector;
                    if (api.tooltip.get(0).id) {
                        tooltipSelector = '#' + api.tooltip.get(0).id;
                    }

                    // Trigger modal listeners
                    AdditionalTriggers.init(tooltipSelector);
                },

                /*show: function(event, api) {
                },
                visible: function(event, api) {
                },
                render: function(event, api) {
                },
                focus: function(event, api) {
                }*/
            }
        };

        // Add selected options to default options
        return $.extend(true, qtipOptions, Tooltip.predefinedOptions[selectedOptions]);
    }
};
