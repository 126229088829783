
var Item = {

	init: function(event) {
		$(document.body).on('change', '.selectric-pricing', function (event) {
			var $this,
				priceTarget,
				oldPriceTarget,
				oldPrice,
				selectedOption;

			$this = $(this);
			selectedOption = $(event.target).find(':selected');
			priceTarget = selectedOption.data('price-target');
			oldPriceTarget = selectedOption.data('old-price-target');
			oldPrice = selectedOption.data('old-price');
			$(priceTarget).text(Hlp.slonum(selectedOption.data('price'))+' €');

			if (oldPrice) {
				$(oldPriceTarget).show().text(Hlp.slonum(oldPrice)+' €');
			} else {
				$(oldPriceTarget).hide();				
			}

		});
	},


};

