
var Cart = {

    height: null,

    // array of allowed inout/output parameters
    parameters: {
        'item-id' : 'item_id',
        'item-title' : 'item_title',
        'related-id' : 'related_id',
        'parent-id' : 'parent_id',
        'domain' : 'domain',
        'option' : 'domain_status',
        'type' : 'type',
        'sub-type' : 'sub_type',
        'discount-code' : 'discount_code',
        'duration' : 'duration',
        'addon-*' : 'addons',
        'customoption-*' : 'custom_options'
    },

    init: function() {
        // Checkout main domain info, popover close
        $('.js-checkoutcart').on('click', '.main-domain-input .icon-close', function() {
            var status = $(this).parents('.maindomain-response').data('status');
            $(this).parents('.popover-box').hide();
            if(status != 'domain-invalid' && status != 'domain-not-supported' && status != 'domain-must-be-registered-with-us' && status != 'domain-must-be-registered-with-us-and-available') {
                Cart.refreshCart();
            }
        });


        // CART //
        $(document.body).on('click', '.js-to-cart', Cart.addItem);
        $('.js-checkoutcart').on('click', '.whois-btn', Cart.toggleWhoisPrivacy);
        $('.js-checkoutcart').on('change click', '.whois-fields select', Cart.toggleWhoisPrivacy);
        $('.js-checkoutcart').on('change', '.whois-fields select', Cart.changeWhoisPrivacyItem);
        $('.js-minicart, .js-checkoutcart').on('click', '.remove-item', Cart.removeItem);


        $('.js-checkoutcart').on('change', '.change-duration, .change-addon, .change-custom-option', function (event) {
            Hlp.targetChange.apply(this, arguments);
            Cart.updateItem.apply(this, arguments);
        });

        $('.content').on('change', '.hosting-duration-switch', Cart.changeHostingDuration);
        $('.js-checkoutcart').on('change', '.main-domain select', Cart.toggleOtherMainDomain);
        $('.js-checkoutcart').on('submit', '.main-domain-input form', Cart.submitMainDomain);
        $('.js-checkoutcart').on('submit', '.ssl-address-input form', Cart.submitSslAddress);
        $('.checkout').on('click', '.js-toggle-register', Cart.toggleRegister);
        $('#register-form').on('blur', '#email', Customer.exists);
        $('.content').on('click', '.order-confirm:not(.btn-loading)', Cart.save);
        $('.checkout-stage').on('click', '.button-next:not(.btn-loading):not(.order-confirm)', Cart.nextStep); // nepotreben delegation?

        /* Mora biti na koncu, ko se že vse naloži */
        if ($('.checkout').length) {
            Cart.start();
        }

    },

    show: function(html) {
        var target = '.js-checkoutcart, .js-minicart .cart-holder',
            $target = $(target);

        if (Cart.isDoneLoading()) {
            $target.html(html);

            if (! Cart.isMiniCart()) {
                // Spremeni število izdelkov v mini-cartu
                var cartQty = $target.find('.cart-qty');

                if (cartQty.length) {
                    MiniCart.changeQuantity(cartQty.text());
                }
            }

            Cart.reset();
            return true;
        } else {
            return false;
        }

    },

    loading: function() {
        $('.js-checkoutcart').startLoading({ 'parentheight' : true });
        if (Cart.isMiniCart()) {
            $(document.body).trigger('cart--loading');
        }
    },

    stopLoading: function() {
        $('.js-checkoutcart').stopLoading();
        if(Cart.isMiniCart()) {
            if($('#main-header.scrolled').length) {
                $('.js-minicart-strongqty').fadeOut().fadeIn();
            }
        }
    },

    isDoneLoading: function() {
        var $checkoutCart = $('.js-checkoutcart');
        if ($checkoutCart.isLoading()) {
            var $loadingOverlay = $checkoutCart.find('.overlay');
            return parseInt($loadingOverlay.attr('data-loaders-count')) <= 1;
        }
        return true;
    },

    start: function() {
        /*$('#main-content, .checkout-register').css({'min-height' : $('.checkout').height() + 'px'});
        $('#main-content .content').css({'min-height' : $('.checkout').height() - $('#main-content .header').height()  + 'px'});
        $('#main-content .left-side, #main-content .right-side').css({'min-height' : 'auto'});*/
    },

    addAlreadyInCart: function($element) {
        if($element.data('type') == 'domain') {
            $element.addClass('hidden').siblings('.loading-tocart').addClass('hidden').siblings('.from-cart').removeClass('hidden');
        }
    },

    removeAlreadyInCart: function($element) {
        if($element.data('type') == 'domain') {
            $element.removeClass('hidden').siblings('.from-cart').addClass('hidden');
        }
    },

    addLoadingInCart: function($element) {
        if($element.data('type') == 'domain') {
            $element.addClass('hidden').siblings('.loading-tocart').removeClass('hidden');
        }
    },

    removeLoadingInCartWhenError: function($element) {
        if ($element && $element.length > 0 && $element.data('type') == 'domain') {
            $element.siblings('.loading-tocart').addClass('hidden');
            $element.siblings('.ajax-error').removeClass('hidden');
        }
    },

    /**
     * Check if item is in cart, using data-domain and data-item-id as selectors
     * @param  int  itemId
     * @param  string  domain
     * @return boolean
     */
    isInCart: function (itemId, domain) {
        return $('[data-domain="'+ domain +'"][data-item-id="'+ itemId +'"]', '.cart-list, .js-minicart-holder').length > 0;
    },

    /**
     * Check if mini-cart is present
     * @return boolean
     */
    isMiniCart: function () {
        return $('.js-minicart').length;
    },

    setCartId: function ($element) {
        var cartId;

        if (! $element.data('cart-id')) {
            return false;
        }
        cartId = $element.data('cart-id');
        return cartId;
    },

    /**
     * Map parameters from data to required in php.
     * Add mini-cart parameter
     * @param  jquerObject $element
     * @param  object inputData
     */
    mapParameters: function($element) {
        var inputData = { 'update-domain': true };

        $.each(Cart.parameters,  function (key, value ) {
        /**
         * Parameters with wildcard also supported. Example: Attribute data-addons-5="3" results to array[addons][5] = 3
         */
            if(/\-\*/.test(key)) {
                var subkey = key.split('-*')[0];
                $.each($element[0].attributes, function() {
                    if(this.specified) {
                        var regex = new RegExp(subkey + '\-');
                        if(regex.test(this.name)) {
                            var subattr = this.name.split('-', 3)[2];
                            if(typeof inputData[value] == 'undefined') {
                                inputData[value] = {};
                            }
                            inputData[value][subattr]  = this.value;
                        }
                    }
                });

            } else {
                if ($element.attr('data-' + key)) {
                    inputData[value] = $element.attr('data-' + key);
                }
            }
        });
        return inputData;
    },

    refreshCart: function() {
        var inputData = {};

        Cart.loading();

        Cart.ajaxCall('GET', inputData);
    },

    addItem: function(event) {
        event.preventDefault();

        var inputData = {},
            $this = $(this),
            $domainToOrder = $('.js-to-order');

        if ($this.is('[class^="btn-"]')) {
            $this.addClass('btn-loading');
        }

        Cart.loading();
        Cart.addLoadingInCart($this);

        inputData = Cart.mapParameters($this);

        if ($this.data('discount-code')) {
            Cart.addDiscount($this.data('discount-code'));
        }

        if ($domainToOrder.length && $domainToOrder.is(':hidden') ) {
            $domainToOrder.hide().removeClass('hidden').fadeIn();
        }

        Cart.ajaxCall('POST', inputData, null, $this).done(function(){
            var eventId = $('#atc-event-id').val();

            if (!eventId) {
                eventId = $('#mini-atc-event-id').val();
            }

            Tracking.track({ eventCategory: 'Checkout', eventAction: 'AddToCart', eventLabel: 'Added', eventId: eventId }, inputData);
            // Če ima link 'redirect-cart' klas, preusmeri na željen url
            if ($this.hasClass('js-redirect-cart') && $this.data('redirect-to')) {
                document.location = $this.data('redirect-to');
            } else if ($this.is('[class^="btn-"]')) {
                $this.removeClass('btn-loading');
            }
            Cart.addAlreadyInCart($this);
            MiniCart.toggle();
        });


    },

    changeWhoisPrivacyItem: function(event) {
        var inputData = {},
            $this = $(this),
            cartId = Cart.setCartId($this);

        Cart.loading();
        inputData = Cart.mapParameters($this);

        //add || remove
        if($this.val() == '1') {
            Cart.ajaxCall('POST', inputData).done(function(){
                Cart.addAlreadyInCart($this);
            });

        } else {
            Cart.ajaxCall('DELETE', {}, cartId);
        }
    },

    removeItem: function(event) {
        event.preventDefault();
        var $this = $(this),
            $item = $this.closest('.js-item'),
            cartId,
            $allItems = $('.js-item'),
            $addButton,
            $el,
            i;

        var numOfItems = $allItems.length;
        cartId = Cart.setCartId($item);

        for (i = 0; i < numOfItems; i++) {
            $el = $($allItems[i]);

            if ($el.data('require-domain-with-us') && $el.data('domain') === $item.data('domain')) {
                $el.data('domain', '');
                $el.attr('data-domain', '');
                Cart.updateItem(event, $el);
            }
        }

        if(!Cart.isMiniCart())Cart.loading();

        var inputData = Cart.mapParameters($this);

        $addButton = $('[data-domain="'+ $item.data('domain') +'"][data-item-id="'+ $item.data('item-id') +'"]');
        Cart.removeAlreadyInCart($addButton);

        Cart.ajaxCall('DELETE', {}, cartId).done(function() {
            Tracking.track({ eventCategory: 'Checkout', eventAction: 'RemoveFromCart', eventLabel: 'Removed'}, inputData);
        });
    },

    updateItem: function(event, $item) {
        if (typeof event.preventDefault !== 'undefined') {
            event.preventDefault();
        }

        var inputData = {},
            $this = $(this),
            cartId;

        if (typeof $item === 'undefined') {
            $item = $this.closest('.js-item');
        }

        cartId = Cart.setCartId($item);

        Cart.loading();

        inputData = Cart.mapParameters($item);

        Cart.ajaxCall('PUT', inputData, cartId).done(function() {
            //Tracking.track({ eventCategory: 'Checkout', eventAction: 'ModifiedItem', eventLabel: 'Updated'});
        });
    },

    /**
     * Add discount to cart
     * @param  {int} discountCode [description]
     */
    addDiscount: function(discountCode) {
        var inputData = {};

        Cart.loading();

        inputData.discount_code = discountCode;

        Cart.ajaxCall('POST', inputData, 'discount').done(function() {
            //Tracking.track({ eventCategory: 'Checkout', eventAction: 'AddDiscount', eventLabel: 'Added'});
        });
    },


    /**
     * Remove discount from cart
     */
    removeDiscount: function(event) {
        var discountCode,
            inputData = {};

        event.preventDefault();

        discountCode = $(DiscountCode.selectors.discountInput).val();

        Cart.loading();

        Cart.ajaxCall('DELETE', inputData, 'discount/'+discountCode).done(function() {
            //Tracking.track({ eventCategory: 'Checkout', eventAction: 'RemoveDiscount', eventLabel: 'Removed'});
        });
    },


    /**
     * Make AJAX call to /api/cart
     * @param  {string} requestType
     * @param  {object} inputData
     * @param  {int} extraUrl
     */
    ajaxCall: function (requestType, inputData, extraUrl, $callableElement) {

        var url = '/api/cart';

        if (typeof requestType === 'undefined') {
            return false;
        }

        if (typeof extraUrl !== 'undefined' && extraUrl !== null) {
            url += '/'+extraUrl;
        }

        switch (requestType) {
        case 'PUT':
            inputData._METHOD = 'PUT';
            break;
        case 'UPDATE':
            inputData._METHOD = 'UPDATE';
            break;
        case 'DELETE':
            inputData._METHOD = 'DELETE';
            break;
        default:
            //console.log(requestType);
        }

        return $.ajax({
            url: url+'?mini-cart=' + (Cart.isMiniCart() ? 1 : 0),
            type: requestType,
            data: inputData,
            dataType: 'html',
            success: function(html) {
                Cart.show(html);
                Cart.stopLoading();
            },
            error: function(jqXHR, exception) {
                Cart.stopLoading();
                Cart.removeLoadingInCartWhenError($callableElement);
            },
            // always ne podpira CORS klicev ?
            always: function(response) {
                Cart.stopLoading();
                Cart.removeLoadingInCartWhenError($callableElement);
            },
        });
    },

    save: function(event) {
        event.preventDefault();
        var $orderConfirm = $('.js-order-confirm');

        // Cart is already beeing saved
        if($orderConfirm.hasClass('btn-loading')) {
            return false;
        }

        var link = $(this);

        $orderConfirm.addClass('btn-loading');


            Customer.isLoggedin(function(){
                var is_logged = this;
                if (is_logged.valueOf()) {
                    //pojdi naprej
                    Cart.close(link);

                //ni prijavljen
                } else {
                    //še vedno odprt choice med register/login
                    if(!$('.js-checkout-register').is(':visible')) {
                        $('.js-checkout-choice .js-toggle-register')[0].click();
                        $orderConfirm.removeClass('btn-loading');
                    } else {
                        Customer.create(function(){
                            var customer_created = this;
                            if(customer_created) {
                                //pojdi naprej
                                Cart.close(link, true);
                            } else {
                                $orderConfirm.removeClass('btn-loading');
                            }
                        });
                    }
                }
            });

    },

    imitateOrderClose: function() {
        $('.js-order-confirm.button-next').trigger('click');
    },

    close: function(link, reloadOnErrors = false) {
        Cart.removeErrors();
        $.ajax({
            url: link.attr('href'),
            type: 'POST',
            data: {'comment' : $('textarea#comment').val()},
            dataType: 'json',
            success: function(data) {
                if (data.hash) {
                    window.location.href = link.data('redirectto')  + '/' + data.hash + (data.eventId ? ('?evt=' + data.eventId) : '');
                } else {
                    if (data.errors) {
                        if (reloadOnErrors) {
                            location.reload();
                            return;
                        }
                        Cart.handleErrors(data.errors);
                    } else {
                        Cart.refreshCart();
                    }

                    $('.js-order-confirm').removeClass('btn-loading');
                }
            },
            error: function(jqXHR, exception) {
                $('.js-order-confirm').removeClass('btn-loading');
                Cart.refreshCart();
            }
        });
    },

    removeErrors: function () {
        var $lists = $('ul.cart-list li.item');
        var $errors = $('ul.cart-list li.item div.errors');

        if ($lists.length > 0) {
            $lists.removeClass('item-error');
        }

        if ($errors.length > 0) {
            $errors.remove();
        }
    },

    handleErrors: function (errors) {
        if (errors) {
            var key, errorTarget, error, errEl, $errorNode, $cartEl;

            for (key in errors) {
                errEl = '<div class="errors">';
                for (errorTarget in errors[key]) {
                    for (error in errors[key][errorTarget]) {
                        errEl += '<div data-error-target="' + error + '" class="single-error"><strong>' + errors[key][errorTarget][error] + '</strong></div>';
                    }
                }

                errEl     += '</div>';
                $errorNode = $(errEl);
                $cartEl    = $('li.item[data-cart-id="' + key + '"]');

                if ($cartEl.length > 0) {
                    $cartEl.addClass('item-error');
                    $cartEl.append($errorNode);
                }
            }
        }
    },

    resized: function(e) {
        Cart.reset();
        Cart.height = $(e.target).height();
    },

    reset: function() {
        $(document.body).trigger('input-handlers', '.js-checkoutcart');
    },

    toggleRegister: function(event) {
        event.preventDefault();
        var choice = $('.js-checkout-choice');
        var register = $('.js-checkout-register');

        if(choice.is(':visible')) {
            choice.fadeOut(400, function() {
                register.hide().removeClass('hidden').fadeIn();
            });
        } else {
            register.fadeOut(400, function(){
                choice.hide().removeClass('hidden').fadeIn();
            });
        }
    },

    changeHostingDuration: function(event) {

        event.preventDefault();
        var $this = $(this);
        var duration = $this.val();
        var parent = $this.closest('.js-packagelist');
        var opt = $this.find('option:selected', parent);
        var dur_text = opt.text();
        var p1, p2,
            $p1 = $('.discount-info .p1', parent),
            $p2 = $('.discount-info .p2', parent);
        var parents;

        //promo discount
        if(parseFloat(opt.data('promo-discount-value')) === 0 || typeof opt.data('promo-discount-value') === 'undefined') {
            p1 = false;
            $p1.hide();
        } else {
            p1 = true;
            $p1.fadeIn();
            $('.discount-info .promo-discount-value', parent).text(opt.data('promo-discount-value')).show();
        }
        //duration discount
        if(parseFloat(opt.data('time-discount-value')) === 0 || typeof opt.data('time-discount-value') === 'undefined') {
            p2 = false;
            $p2.hide();
        } else {
            p2 = true;
            $p2.fadeIn();
            $('.discount-info .time-discount-value', parent).text(opt.data('time-discount-value')).show();
        }
        $('.discount-info .discount-plus',parent).toggle((p1 && p2));
        $('.discount-info', parent).toggle((p1 || p2));

        // Specify parent element / use own (price-box's) parent element
        if ($this.data('contain-price-change') === true) {
            parents =  $this.closest('.js-parentelement').find('.hosting-durations');
        } else if (typeof $this.data('switch-parents') === 'undefined') {
            parents =  $('.hosting-durations', parent);
        } else {
            parents = $($this.data('switch-parents')).find('.hosting-durations', parent);
        }

        parents.each(function(i,e){
            var $element = $(e);
            $element.siblings('.old-price').html('<br>');

            var priceOld = 0;
            var priceNew = parseFloat($element.data('price-new' + duration).replace(',', '.'));

            if ($element.data('price-old' + duration)) {
                priceOld = parseFloat($element.data('price-old' + duration).replace(',', '.'));
            } else {
                priceOld = priceNew;
            }

            //has promo
            if( priceOld !== priceNew ) {
                $element.siblings('.old-price').text($element.data('price-old' + duration)).fadeIn();
            }

            $element.siblings('.new-price').fadeOut(400, 'swing', function() {
                $(this).text($element.data('price-new' + duration)).fadeIn();
            });
            $element.siblings('.dur-text').find('span').text(dur_text);
            var $commonParent = $element.closest('.order-box-holder.price-box:not(.ignore-update-price)');
            $commonParent.find('.selectric-wrapper span.label').text(dur_text);
            $commonParent.find('form.p0 input[name="d"]').val(duration);
        });

    },

    /* unused */
    /*changePriceDuration: function(event) {
        var duration = $(this).val();
        var e = $(this);
        e.parents('.selectric-wrapper').siblings('.the-price').fadeOut(400, 'swing', function() {
            $(this).text($(e).data('price-duration' + duration)).fadeIn();

        });

    },*/

    /**
     * Toggle Whois privacy via button/select
     * @param  {[type]} event   [description]
     * @param  {[type]} element [description]
     * @return {[type]}         [description]
     */
    toggleWhoisPrivacy: function (event, element) {

        event.preventDefault();

        var target;
        var $target;
        var targetData;

        if (typeof  element ===  'undefined') {
            target = this;
        } else {
            target = element;
        }
        $target = $(target);

        // sprožil je gumb
        if ($target.hasClass('whois-btn')) {
            var whoisFields = $target.closest('.js-item').find('.whois-fields');
            whoisFields.toggle('100').removeClass('hidden');
            $target.toggleClass('visible');
        }
    },

    /**
     * Toggle other main domain select
     * @param  {object} event   [description]
     * @param  {element} element [description]
     */
    toggleOtherMainDomain: function (event, element) {

        var target,
            $target,
            $cartItem;

        if (typeof  element ===  'undefined') {
            target = this;
        } else {
            target = element;
        }

        $target = $(target);
        $cartItem = $target.closest('.js-item');

        // sprožil je gumb
        if ($target.val() == 'other-domain') {
            $cartItem.find('.domain-input').hide().removeClass('hidden').show('300');
        } else {
            $cartItem.find('.domain-input').hide('300');
            //Cart.applyMainDomain($cartItem, $target.val());
            $cartItem.attr('data-domain', $target.val());

            Cart.updateItem(event, $cartItem);


        }


    },

    /**
     * Check main domain validity, show popup and change it via API
     * @param  {[type]} event [description]
     * @return {[type]}       [description]
     */
    submitMainDomain: function(event) {
        event.preventDefault();
        var $this = $(this),
            $input = $this.find('input'),
            domain = DomainCheck.validate($input.val()),
            $button = $this.find('button'),
            $buttonText = $button.children('strong'),
            popup = false,
            $parentLi = $this.closest('li.cart-item'),
            itemId = $parentLi.data('item-id');

        if (domain) {
            $input.val(domain);
        }

        $this.siblings().find('.popover-box').hide();
        $this.addClass('form-loading');

        $button.addClass('btn-loading');

        var promise = DomainCheck.mainDomain(domain, itemId);

        if(!promise) {
            $this.removeClass('form-loading');
            $button.removeClass('btn-loading');
            return false;
        }

        promise.success(function (data) {
            var statusClass = 'notice-warning',
                statusClasses = 'notice-warning notice-success notice-error',
                statusMap = {
                    'domain-not-supported' : 'notice-success',
                    'general-exception' : 'notice-error',
                    'domain-invalid' : 'notice-error',
                    'domain-registered' : 'notice-success',
                    'domain-must-be-registered-with-us' : 'notice-error',
                    'domain-must-be-registered-with-us-and-available': 'notice-error',
                    'domain-registered-with-us' : 'notice-success',
                    'domain-available' : 'notice-success'
                };

            // Remove loading
            $this.removeClass('form-loading');

            // Add domain status response to items
            popup = $this.siblings('[data-status="'+ data.status +'"]');

            // Add appropriate class to form (border color)
            if (typeof statusMap[data.status] !== 'undefined') {
                statusClass = statusMap[data.status];
            }
            $input.closest('form').removeClass(statusClasses).addClass(statusClass);

            // If popup exists, show it
            if(popup && popup.length) {
                popup.css({'top' : '45px'}).show().find('.popover-box').show();
            }

            $button.removeClass('btn-loading');

            var option;
            // If domain check was successful, assign item options to addToCart button
            if(statusClass == 'notice-success') {
                option = 'new';
                switch (data.status) {
                case 'domain-registered':
                    option = 'transfer_in';
                    break;
                case 'domain-registered-with-us':
                    option = 'registered';
                    break;
                }

                if($('.js-to-cart', popup).length) {
                    if(typeof data.item != 'undefined' && typeof data.domain != 'undefined') {
                        $('.js-to-cart', popup).attr('data-item-id', data.item.id)
                            .attr('data-domain', data.domain);
                    }
                    $('.js-to-cart', popup).attr('data-type', 'domain')
                        .attr('data-option', option);
                }

                // Domain is valid, add it to cart. Changes class on fail
                if(Cart.applyMainDomain($this.closest('.js-item'), domain)) {
                    if($buttonText.data('on-success')) {
                        $buttonText.html($buttonText.data('on-success'));
                    }

                    $input.parent().addClass('notice-success');
                } else {
                    $input.parent().addClass('notice-error');
                    $this.removeClass('form-loading');
                    return false;
                }
            } else if (data.status === 'domain-must-be-registered-with-us') {
                option = 'transfer_in';

                if($('.js-to-cart', popup).length) {
                    if(typeof data.item != 'undefined' && typeof data.domain != 'undefined') {
                        $('.js-to-cart', popup).attr('data-item-id', data.item.id)
                            .attr('data-domain', data.domain);
                    }
                    $('.js-to-cart', popup).attr('data-type', 'domain')
                        .attr('data-option', option);
                }
            } else if (data.status === 'domain-must-be-registered-with-us-and-available') {
                option = 'new';

                if($('.js-to-cart', popup).length) {
                    if(typeof data.item != 'undefined' && typeof data.domain != 'undefined') {
                        $('.js-to-cart', popup).attr('data-item-id', data.item.id)
                            .attr('data-domain', data.domain);
                    }
                    $('.js-to-cart', popup).attr('data-type', 'domain')
                        .attr('data-option', option);
                }
            }
        });

    },



    /**
    *  Submit main domain to TempCart item
    * @return boolean
    */

    applyMainDomain: function($element, domain) {
        var inputData,
            cartId;

        $element.attr('data-domain', domain);

        inputData = Cart.mapParameters($element);

        cartId = Cart.setCartId($element);

        return $.ajax({
            url: '/api/cart/main-domain/'+ cartId,
            type: 'POST',
            data: inputData,
            success: function(data) {
                if (typeof data.items !== 'undefined') {
                    return true;
                }
                return false;
            },
            error: function(jqXHR, exception) {
                return false;
            },
            // always ne podpira CORS klicev ?
            always: function(response) {
                return false;
            },
        });
    },


    /**
     * Check SSL ADDRESS validity, show popup and change it via API
     * @param  {object} event [description]
     * @return {boolean}       [description]
     */
    submitSslAddress: function(event) {
        event.preventDefault();
        var $this = $(this),
            $input = $this.find('input'),
            domain = DomainCheck.validate($input.val(), false),
            $button = $this.find('button'),
            $buttonText = $button.children('strong'),
            $cartItem = $this.closest('.cart-item');
        if(domain) {
            $input.val(domain);
        }
        $this.siblings().find('.popover-box').hide();
        $this.addClass('form-loading');

        $button.addClass('btn-loading');

        // Domain is valid, add it to cart. Changes class on fail
        var promise = Cart.applyMainDomain($this.closest('.js-item'), domain);

        promise.success(function(data, xhr) {
            $button.removeClass('btn-loading');
            $this.removeClass('form-loading');
            if($buttonText.data('on-success')) {
                $buttonText.html($buttonText.data('on-success'));
            }

            $input.parent().addClass('notice-success').removeClass('notice-error');
            $cartItem.find('[data-error-target="domain"]').html('');
            Cart.refreshCart();
        });

        promise.error(function(xhr) {
            $input.parent().addClass('notice-error');
            $this.removeClass('form-loading');
            $button.removeClass('btn-loading');
            if($buttonText.data('on-error')) {
                $buttonText.html($buttonText.data('on-error'));
            }
            $input.parent().addClass('notice-error').removeClass('notice-success');

            var data = xhr.responseJSON;
            var focusChanged = false;

            if (typeof data.errors !== 'undefined') {
                for (var key in data.errors) {

                    var holder = $('.notice[data-key="' + key + '"]', $cartItem);
                    for (var i in data.errors[key]) {
                        var html = $('<strong class="notice-text"><div class="error">' + data.errors[key][i] + '</div></strong>');
                        holder.hide();
                        console.log(i);
                        if (i == 0) {
                            holder.html(html);
                        } else {
                            holder.append(html);
                        }
                        holder.removeClass('hidden').fadeIn();
                        if (focusChanged === false) {
                            holder.parent().addClass('notice-error').velocity('scroll', { duration: 500, offset: -90, easing: 'linear' });
                            focusChanged = true;
                        } else {
                            holder.parent().addClass('notice-error');
                        }
                    }
                }
            }

            return false;
        });

    },

    /**
     * Moving to next step
     * Loading class + notify trackers
     *
     * @param object event
     */
    nextStep: function ()
    {
        var $this = $(this);
        //Tracking.track({ eventCategory: 'Checkout', eventAction: 'NextStep', eventLabel: 'Next'});
        $this.addClass('btn-loading');
    }
};
