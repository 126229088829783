/**
 * Package comparison - standalone
 */
var Comparison = {
    filters: {
        selector: '.comparison-filters input',
        $elements: null,
        packages: {
            enabled: 0,
            maxLimit: 4,
            minLimit: 1
        }
    },
    lastEnabledPackage: null,

    /**
     * Initiates selectors and populates needed variables
     */
    init: function() {
        Debug.log('Comparison.init');
        this.populateVariables();

        this.filters.$elements.on('change', function() {
            var $this = $(this);

            if ($this.is(':checked')) {
                Comparison.showPackage($this);
            } else {
                Comparison.hidePackage($this);
            }
        });
    },


    /**
     * Updates disabled/enabled packages counter
     */
    updateCounters: function() {
        Debug.log('Comparison.updateCounters');
        Comparison.filters.packages.enabled = 0;

        Comparison.filters.$elements.each( function (index, element) {
            if ($(element).is(':checked')) {
                Comparison.filters.packages.enabled++;
            }
        });
    },


    /**
     * Checks if number of displayed packages is exceeds the limit
     */
    checkMaxLimit: function() {
        Debug.log('Comparison.checkMaxLimit');
        if (Comparison.filters.packages.enabled > Comparison.filters.packages.maxLimit) {
            Comparison.disableFirstEnabled();
        }
    },


    /**
     * Check lower limit of packages
     */
    isBelowMinLimit: function() {
        Debug.log('Comparison.isBelowMinLimit');
        if (Comparison.filters.packages.enabled < Comparison.filters.packages.minLimit) {
            return true;
        }
        return false;
    },


    /**
     * Increase enabled packages counter
     */
    increaseEnabled: function() {
        Debug.log('Comparison.increaseEnabled');
        Comparison.filters.packages.enabled++;
    },


    /**
     * Decrease enabled packages counter
     */
    decreaseEnabled: function() {
        Debug.log('Comparison.decreaseEnabled');
        Comparison.filters.packages.enabled--;
    },


    /**
     * Populates variables
     */
    populateVariables: function() {
        Debug.log('Comparison.populateVariables');
        this.filters.$elements = $(this.filters.selector);
        Comparison.updateCounters();
    },


    /**
     * Disables first enabled package
     * Direction: from cheaper to more expensive
     */
    disableFirstEnabled: function() {
        Debug.log('Comparison.disableFirstEnabled');
        Comparison.filters.$elements.each( function(index, element) {
            var $element = $(element),
                currentPackageID = $element.data('cmp-pkgid');

            if ($element.is(':checked') && Comparison.lastEnabledPackage != currentPackageID) {
                $element.prop('checked', false).trigger('change');
                return false;
            }
        });
    },


    /**
     * Hides (fades) package
     * @param  {jquery Object} $element
     */
    hidePackage: function($element) {
        Debug.log('Comparison.hidePackage');
        var pkgID = $element.data('cmp-pkgid');

        Comparison.decreaseEnabled();

        if (Comparison.isBelowMinLimit()) {
            $element.prop('checked', true);
            Comparison.increaseEnabled();
            return false;
        }

        if (pkgID) {
            $('#'+ pkgID).velocity(
                'fadeOut',
                { 'duration' : 100 }
            );
        }

        return true;
    },


    /**
     * Shows (fades) package
     * @param  {jquery Object} $element
     */
    showPackage: function($element) {
        Debug.log('Comparison.showPackage');
        var pkgID = $element.data('cmp-pkgid');

        this.lastEnabledPackage = pkgID;

        Comparison.increaseEnabled();
        Comparison.checkMaxLimit();

        if (pkgID) {
            $('#'+ pkgID).velocity(
                'fadeIn',
                { 'duration' : 100 }
            );
        }
    },
};
