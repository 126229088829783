var CoinPayments = {

    init: function() {
        $('.js-currencyselect').on('click', this.selectCurrency);
        $('.js-coinpayments-form').on('click', this.confirmCurrency);
    },

    selectCurrency: function(event) {
        var $this = $(this),
            conversionUrl = '/coinpayments/convert',
            cartTotalAmount = $('.js-cartsummary-amount').data('cartsummary-amount'),
            selectedCurrency,
            $convertedAmount = $('.js-convertedamount');

        selectedCurrency = $this.data('currency');

        $convertedAmount.addClass('loading');

        var startWithLetter = ['P','T','K','H','F','S','Š','C','Č'];
        var btnText = 'Plačaj z ' + selectedCurrency;
        if ($.inArray(selectedCurrency.charAt(0), startWithLetter) !== -1) {
            btnText = 'Plačaj s ' + selectedCurrency;
        }

        $('.js-coinpayments-form').text(btnText);

        $.ajax({
            crossDomain: true,
            url:  Hlp.getApi() + conversionUrl + '/EUR/' + selectedCurrency,
            type: 'GET',
            dataType: 'json',
            data: {amount : cartTotalAmount},
            success: function(data) {
                $convertedAmount.text(data.amount + ' ' + data.currency).removeClass('loading');
            },
            error : function(xhr) {
                return false;
            }
        });
    },

    confirmCurrency: function(event) {
        event.preventDefault();
        var $this = $(this),
            formData,
            $mdlContent;

        $mdlContent = $('.mdl-content').startLoading();

        $this.closest('form').serialize();
        formData = $this.closest('form').serialize();

        $.ajax({
            crossDomain: true,
            url:  '/modals/coinpayments',
            type: 'POST',
            data: formData,
            dataType: 'json',
            success: function(data) {
                if (typeof data.message !== 'undefined') {
                    var message = '<div class="notice-error"><span class="notice-text">'+ data.message +'</span></div>';
                    $mdlContent.append(message);
                    $mdlContent.stopLoading();
                } else if (typeof data.data.html !== 'undefined') {
                    $this.closest('#mdl').html(data.data.html);
                }
            },
            error : function(xhr) {
                return false;
            }
        });
    }

};
