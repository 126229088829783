
var DiscountCode = {
    selectors : {
        cartDiscount: '.cart-discount',
        discountInput: '#discount-input',
    },

    init: function () {
        $(document.body).on('submit', '.discount-field.add-code', DiscountCode.checkDiscount);
        $(document.body).on('submit', '.discount-field.remove-code', Cart.removeDiscount);
    },

    /**
     * Check if discount is legit
     * @return {Boolean}
     */
    checkDiscount: function (event) {
        var inputData = {},
            apiUrl = '/discount/code';

        event.preventDefault();

        inputData.code = $(DiscountCode.selectors.discountInput).val();
        $.ajax({
            url: Hlp.getApi() + apiUrl,
            type: 'GET',
            data: inputData,
            success: function(response) {
                var $cartDiscount = $(DiscountCode.selectors.cartDiscount),
                    statusClass = 'invalid-code';

                if (typeof response.code !== 'undefined') {
                    statusClass = 'valid-code';
                }

                $cartDiscount.removeClass('valid-code invalid-code').addClass(statusClass);

                if (statusClass == 'valid-code') {
                    DiscountCode.changeDiscountCode(response.code);
                    Cart.addDiscount(response.code);
                }
            },
            error: function(jqXHR, exception) {
                console.log(jqXHR);
                console.log(exception);
            },
            // always ne podpira CORS klicev ?
            always: function(response) {
            },
        });

    },

    changeDiscountCode: function (discountCode) {
        $(DiscountCode.selectors.cartDiscount).attr('data-discount-code', discountCode);
    }
};
