/*jslint browser: true*/
/*global $, jQuery, alert*/

var Hlp = {

    init: function() {
        $(document.body).on('change', '.js-value-change', Hlp.targetChange);
        $(document.body).on('click', '.js-counter-change', Hlp.counterChange);
        $(document.body).on('change, keyup', 'input.counter-target', Hlp.counterChange);

        Hlp.conditionalDisplay();
    },

    /**
     * Vrne random API URI - TODO fallback ?
     * @return string
     */
    getApi: function () {
        var num = Math.floor((Math.random() * 3));
        return API_URL[num];
    },

    startsWith: function (str, searchfor) {
        return str.indexOf(searchfor) === 0;
    },
    trimStr: function (str) {
        return str.replace(/(^[\s\n\t\0,.-]*)|([\s\n\t\0\-,.]*$)/g, '');
    },
    isNoE: function (obj) {
        if (typeof obj === "undefined") {
            return true;
        }
        if (obj === undefined || obj === null) {
            return true;
        }
        return false;
    },

    defined: function (obj) {
        return !Hlp.isNoE(obj);
    },

    isInt: function (value) {
        var x;
        if (isNaN(value)) {
            return false;
        }
        x = parseFloat(value);
        return (x | 0) === x;
    },

    slonum: function (num) {
        return parseFloat(num).toLocaleString('sl-SI', {minimumFractionDigits: 2, maximumFractionDigits: 2});
    },

    getURLParameters: function (paramName,sURL)
    {
        if(typeof sURL === 'undefined') {
            sURL = window.document.URL.toString();
        }
        var response = {};
        if (sURL.indexOf("?") > 0)
        {
            var arrParams = sURL.split("?");
            var arrURLParams = arrParams[1].split("&");
            var arrParamNames = /*new Array(arrURLParams.length);*/{};
            var arrParamValues = /*new Array(arrURLParams.length);*/{};
            var arrAllParam = /*new Array(arrURLParams.length);*/{};
            var i;
            for (i = 0; i<arrURLParams.length; i++)
            {
                var sParam =  arrURLParams[i].split("=");
                arrParamNames[i] = sParam[0];
                if (sParam[1] !== "") {
                    arrParamValues[i] = decodeURIComponent(sParam[1]);
                } else {
                    arrParamValues[i] = null;
                }
            }

            for (i=0; i<arrURLParams.length; i++)
            {
                arrAllParam[arrParamNames[i]] = arrParamValues[i];
                if (arrParamNames[i] == paramName)
                {
                    //alert("Parameter:" + arrParamValues[i]);
                    response = arrParamValues[i];
                }
            }
            if (!paramName || !response) {
                response = arrAllParam;
            }
        }
        return response;
    },

    /**
     * Add GET parameter to url
     *
     * @param elements Listo of all new parameters
     * @returns {string} new URI
     */
    appendParametersToUrl: function (elements) {
        Debug.log('Hlp.appendParametersToUrl');
        // Dodaj query v URL
        var pathname = window.location.pathname;
        // Uporabi obstoječe URL parametre
        var parameters = Hlp.getURLParameters();
        var url = pathname;

        if (!Hlp.defined(parameters)) {
            parameters = {};
        }

        if (typeof elements !== 'undefined') {
            $.each(elements, function (index, val) {
                parameters[index] = val;
            });
        }

        url += '?' + $.param(parameters);

        // Dodaj v history - check za IE9
        if (typeof history.pushState == 'function') {
            history.pushState(null, null, url);

        }
        return url;
    },

    inViewport: function  (element) {

        if (typeof jQuery === "function" && element instanceof jQuery) {
            element = element[0];
        }
        if (typeof element === 'undefined') {
            return false;
        }
        var rect = element.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
        );
    },

    /**
     * Pogojni prikaz elementov
     * Vsebuje dodatno kodo za uporabo s selectricom
     * @triggers .js-conditional-display && .selectric-js-conditional-display
     * @args data-cond-option, data-cond-value
     * @return {[type]} [description]
     */
    conditionalDisplay: function () {
        Debug.log('Hlp.conditionalDisplay');
        var $targets,
            $elements,
            triggerClass = 'js-conditional-display',
            selectricClass = '.selectric-' + triggerClass;

        $elements = $(selectricClass + ', .' + triggerClass);

        if ($elements) {
            // Vsi conditional elementi
            $.each($elements, function (index, val) {
                var options = {
                        option: false,
                        value: false
                    },
                    $val = $(val);

                options.option = $val.data('cond-option');
                options.value = $val.data('cond-value');

                // element nima določennih optionov, preverimo child elemente
                if ($val.data('cond-option') === false) {
                    var tempElement = $($val.find(':data(\'cond-option\')'));
                    options.option = tempElement.data('cond-option');
                    options.value = tempElement.data('cond-value');
                }

                if (options.option && options.value) {
                    $(options.option).on('change focus blur click', function (event) {
                        var $this = $(this);

                        if ($this.val() == options.value || (options.value == 'isset' && $this.val()) || $this.hasClass(options.value) || $this.is(options.value) ) {
                            //console.log("visible:", index, val);
                            // Prikaže optione, ki se ujemajo z vrednostjo
                            $val./*show().*/removeClass('hidden').closest(selectricClass).removeClass('selectric-hidden');
                        } else {
                            //console.log("hide:", index, val);
                            // Skrij optione, ki se NE ujemajo z vrednostjo
                            $val./*hide().*/addClass('hidden').closest(selectricClass).addClass('selectric-hidden');
                        }
                        if ($this.hasClass('selectric') || $this.is('select')) {
                            $($val.selectric('refresh').closest('.selectric select')).selectric('refresh');
                        }
                    });
                }
            });
            return true;
        }
        return false;

    },

    /**
     * Prekopira določene vrednosti iz izvirnega elementa v ciljne elemente
     * HTML primer: data-change-target=".value-target" data-change-attrib='{"value" : "data-duration"}' data-change-target-function='closest'
     * @data-param  data-change-target (obvezno)
     * @data-param  data-change-attrib (obvezno)
     * @data-param  data-change-target-function
     * @param  event
     */
    targetChange: function (event) {
        var $this = $(this),
            $targets,
            targetAttrObject;

        // Pridobi vse ciljne elemente
        switch($this.data('change-function')) {
            case 'closest':
                $targets = $this.closest($this.data('change-target'));
                break;
            case 'siblings':
                $targets = $this.siblings($this.data('change-target'));
                break;
            case 'children':
                $targets = $this.children($this.data('change-target'));
                break;
            case 'find':
                $targets = $this.find($this.data('change-target'));
                break;
            default:
                $targets = $($this.data('change-target'));
        }

        // Pridobi object z vsemi izbornimi/ciljnimi attributi
        targetAttrObject = $this.data('change-attrib');
        // Če gre za select, selectaj izbrani option. Posledično se lahko brez problema uporablja .attr() za branje vrednosti
        if ($this.is('select')) {
            $this = $this.find(':selected');
        }

        // Loop čez vse elemente, ki jih je potrebno prekopirati
        $.each(targetAttrObject, function(currentAttr, targetAttr) {
            // Vrednost izvirnika
            var currentValue;

            if (currentAttr == 'value' && $this[0].tagName == 'INPUT') {
                currentValue = Hlp.isNoE($this.data('change-attrib-value')) ? $this.val() : $this.data('change-attrib-value');
            } else {
                currentValue = $this.attr(currentAttr);
            }

            if (currentValue < 0) {
                $targets.each(function (index, value) {
                    //Unset attribute
                    $(value).removeAttr(targetAttr);
                });
            } else {
                // Loop čez vse ciljne elemente
                $targets.each(function (index, value) {
                    // Določi novo vrednost ciljnemu elementu
                    $(value).attr(targetAttr, currentValue);
                });
            }
        });
    },

    counterChange: function (event) {

        event.preventDefault();
        var $this = $(this);
        //var $target = $this.parent().find('.counter-target');
        var $parent = $this.closest('.price-box');
        var $target = $parent.find('.counter-target');
        var value = parseInt($target.val());
        if(!Number.isInteger(value)) {
            value = 1;
        }

        var diff      = $target.data('change-target-change-by') || 1;
        var targetMin = $target.data('change-target-counter-min') || 0;

        if ($this.hasClass('counter-minus')) {
            value -= diff;
        }
        if ($this.hasClass('counter-plus')) {
            value += diff;
        }

        if ($target.data('change-target-price-step') && value >= targetMin && value <= $target.data('change-target-counter-max')) {
            var $priceHolder = $parent.find('.hosting-price .new-price');
            var priceText    = $priceHolder.text().replace(',', '.');
            var price        = parseFloat(priceText);

            if($this.hasClass('counter-minus')) {
                price -= parseFloat($target.data('change-target-price-step')) * diff;
            }

            if($this.hasClass('counter-plus')) {
                price += parseFloat($target.data('change-target-price-step')) * diff;
            }

            price = Hlp.slonum(Math.round(price * 100) / 100) + ' €';

            if (priceText.includes('m')) {
                price += ' / m';
            }

            $priceHolder.text(price);
        }

        if ($target.data('change-target-counter-max')) {
            if(value > $target.data('change-target-counter-max')) {
                value = $target.data('change-target-counter-max');
            }
        }

        if (value < targetMin) {
            value = targetMin;
        }

        $target.data('change-attrib-value', value - targetMin);
        $target.val(value).trigger('change');
    }
};

$.fn.startLoading = function (options) {
    return this.each(function (i, el) {

        var defaults = {
            parentheight : false,
            top : 'auto',
            left : 'auto',
            scrollAlign : true
        };

        var settings = $.extend({}, defaults, options);

        el = $(el);

        var ht = '100%';
        var relative = el;
        if (el.data('overlay-parent')) {
            if($(el.data('overlay-parent')).length) {
                ht = $(el.data('overlay-parent')).height() + 'px';
            }
        }

        if (settings.parentheight) {
            ht = el.parent().height() + 'px';
            relative = el.parent();
        }

        var $existingLoader = el.find('.overlay');
        if ($existingLoader.length) {
            var currentCount = parseInt($existingLoader.attr('data-loaders-count'));
            currentCount++;
            $existingLoader.attr('data-loaders-count', currentCount);
            return false;
        }

        var loader = $('<div data-loaders-count=\'1\' class="overlay loading"><div class="css-loader"></div></div>').css({'position' : 'absolute', 'height' : ht, 'width' : '100%', 'top' : settings.top, 'left' : settings.left });
        relative.data('original-position',  relative.css('position'));
        relative.css({'position' : 'relative'}).data('');
        el.prepend(loader);
        if (settings.scrollAlign) {
            var st = $(window).scrollTop();
            var p = ($(window).height() - (el.offset().top - st)) / 2;
            var bo = $(window).height() - el.offset().top - el.height() + st;
            if (bo < 0) {
                $('.overlay .css-loader').css({'top' : p + 'px'});
            }
        }
        return true;
    });
};

$.fn.stopLoading = function ( options ) {

    return this.each(function (i, el) {
        var $el = $(el),
            $loader = $el.find('.overlay');

        var currentCount = parseInt($loader.attr('data-loaders-count'));
        currentCount--;
        if (currentCount > 0) {
            $loader.attr('data-loaders-count', currentCount);
            return false;
        }

        $('.overlay', $el).remove();
        $el.parent().css( { 'position' : $el.parent().data('original-position') } );
        return true;
    });
};

/**
 * Check if there is loading overly in element
 * @return {Boolean}
 */
$.fn.isLoading = function () {
    return $('.overlay', this).length > 0;
};
