
var Checkout = {
    init : function () {

        $('.js-show-payment').on('click', function(event) {
            event.preventDefault();
            var $this = $(this),
                $target = $this.closest('.data').find('.payment-options');

            if ($this.data('hide-element')) {
                $($this.data('hide-element')).hide();
            }

            $target.slideDown();
            $this.closest('.js-payment-trigger').slideUp();
        });
        $('.js-category-pricing').on('change', Checkout.changeSelectricPrice);
        $('.js-category-duration').on('change', Checkout.changeSelectricDuration);

        $('.js-paysafecard').on('click', Checkout.paysafecard);

        $(document).on('click', '.js-prepayment-process', Checkout.payWithPrepayment);

        // We are at last step of checkout
        if ($('.js-final-step').length) {
            if (typeof orderHash !== 'undefined') {
                $.ajax({
                    url: Hlp.getApi() + '/order/'+ orderHash +'/count',
                    type: 'POST',
                    success: function(data) {
                        try {
                            localStorage.clear(); // sisyphus data
                        } catch(e) {
                        }
                    },
                    error : function(xhr) {
                        console.log(xhr);
                    }
                });
            }
        }
    },


    changeSelectricDuration: function (event) {
        var pricingElement = $(this).closest('.collapsable-content').find('.js-category-pricing');
        Checkout.changeSelectricPrice(event, pricingElement);
    },


    /**
     * Pridobi ceno in popust iz select.data, duration iz duration selecta in posodobi ceno in poppust
     * @param  {object} event
     * @param  {element/null} element
     */
    changeSelectricPrice: function (event, element) {
        var $this,
            $priceTarget,
            prices,
            duration,
            $durationTarget,
            $discountTarget,
            $selectedOption;

        if (element) {
            $this = element;
        } else {
            $this = $(this);
        }

        $selectedOption = $($this.find(':selected'));
        $priceTarget = $($selectedOption.data('prices-target'));
        $durationTarget = $($selectedOption.data('duration-target'));
        $discountTarget = $($selectedOption.data('discount-target'));
        prices = $selectedOption.data('prices');
        duration = $durationTarget.val();
        $priceTarget.text(prices[duration].price);

        if (prices[duration].discount) {
            $discountTarget.text(prices[duration].discount);
            $discountTarget.parent().show();
        } else {
            $discountTarget.parent().hide();
        }
    },

    paysafecard: function(event) {
        event.preventDefault();
        var $this = $(this),
            data = {
                url: location.protocol + '//' + location.host + location.pathname
            },
            $paysafe = $('.js-paysafecard'),
            $paysafeHolder = $paysafe.closest('.payment-holder');

        $paysafeHolder.find('.notice-warning').fadeOut();

        $this.addClass('btn-loading');

        $.ajax({
            url: '/paysafecard/url/' + globalOrderID,
            data: data,
            success: function(data) {

                if (typeof data.url === undefined || ! data.success) {
                    $paysafeHolder
                        .append('<p class="notice notice-warning">' +
                            'Pri plačilu je prišlo do napake, prosimo da poskusite ponovno.' +
                            ' V primeru, da se napaka ponovi, kontaktirajte ' +
                            '<a target="_blank" alt="NEOSERV podpora" href="info@neoserv.si">podporo</a>.' +
                            '<br/><i>Napaka: '+ data.message +'</i></p>');

                    $this.removeClass('btn-loading');
                } else {
                    window.location.href = data.url;
                }

            },
            error : function(xhr) {
                return false;
            }
        });
    },

    payWithPrepayment: function (event) {
        event.preventDefault();

        var $this      = $(this);
        var data       = {orderId: $this.data('order')};
        var $successEl = $('.prepayment-modal .prepayment-success');
        var $errorEl   = $('.prepayment-modal .prepayment-error');

        $successEl.fadeOut();
        $errorEl.fadeOut();

        $this.addClass('btn-loading');

        $.ajax({
            url: Hlp.getApi() + '/prepayment/process',
            method: 'POST',
            data: data,
            success: function (response) {
                if (response && response.success) {
                    $successEl.html(response.message).fadeIn();
                } else {
                    $errorEl.html(response.message).fadeIn();
                }
                $this.removeClass('btn-loading');
            },
            error: function (error) {
                $errorEl.html(Checkout.handleError(error)).fadeIn();
                $this.removeClass('btn-loading');
            }
        });
    },

    handleError: function (error) {
        try {
            var errJson = JSON.parse(error.responseText);

            return errJson[Object.keys(errJson)[0]];
        } catch (e) {
            return error.responseText;
        }
    },
};
