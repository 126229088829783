
var Menu = {

	mobileMenu: '.js-mobile-menu',
	mobileMenuClose: '.mobile-menu-close',

	init: function() {
        $('.menu-toggle').on('click', this.toggleMainMenu);

        /* Main-menu/mobile - visibility toggle */
        $(Menu.mobileMenu + ',' + Menu.mobileMenuClose).on('click', Menu.toggleMobileMenu);
	},

    closeMobileMenu: function() {
        $(Menu.mobileMenu).removeClass('open');
        var $navigation = $('.navigation ');
        $navigation.removeClass('active');
    },

    toggleMobileMenu: function() {
        var $mobileMenu = $(Menu.mobileMenu);
        $mobileMenu.toggleClass('open');
        var $navigation = $('.navigation ');
        $navigation.toggleClass('active');
        if ($mobileMenu.hasClass('open')) {
            MiniCart.close();
        }
    },

    toggleMainMenu: function() {
        var $this = $(this);

        $this.closest('.navigation').toggleClass('closed');
    }
};

