
var Tracking = {

    analytics: null,
    piwik: null,
    facebook: null,

    init: function () {
        // TODO - add event listeners and trigger appropriate actions
        Tracking.orientationChange();
    },


    /**
     * @param eventObject { eventCategory, eventAction, eventLabel }
     * @param dataObject
     */
    track: function (eventObject, dataObject) {
        Debug.log('Tracking.track');

        // Send to google analytics if available
        if (this.analyticsAvailable()) {
            this.sendAnalyticsEvent(eventObject, dataObject);
        }
        // Send to piwik if available
        if (this.piwikAvailable()) {
            var piwikArray = ['trackEvent', eventObject.eventCategory, eventObject.eventAction, eventObject.eventLabel/*, value? */];
            this.piwik.push(piwikArray);
        }
        // Send to Facebook if available
        if (eventObject.eventAction === 'AddToCart' && this.facebookAvailable()) {
            var additionalData = {};
            if (typeof dataObject.item_id !== 'undefined' && typeof dataObject.type !== 'undefined') {
                additionalData.content_type = 'product';
                additionalData.content_ids = [dataObject.item_id];
            }

            var eventData = { event: 'AddToCart' };
            if (eventObject.eventId) {
                eventData.eventID = eventObject.eventId;
            }
            this.facebook('track', 'AddToCart', additionalData, eventData);
        }
    },

    sendAnalyticsEvent: function (eventObject, dataObject) {
        var eventSlug = '';
        var eventData = {};

        switch(eventObject.eventAction) {
            case 'AddToCart':
                eventSlug = 'add_to_cart';
                eventData = {
                    items: [
                        {
                            item_id: dataObject.item_id,
                            item_name: dataObject.item_title || '.' + dataObject.domain.split(".")[1] + ' domena',
                            item_brand: 'Neoserv',
                            item_category: dataObject.type,
                            item_category2: dataObject.sub_type || dataObject.domain.split(".")[1],
                            quantity: 1
                        }
                    ]
                };
                break;
            case 'RemoveFromCart':
                eventSlug = 'remove_from_cart';
                eventData = {
                    items: [
                        {
                            item_id: dataObject.item_id,
                            item_name: dataObject.item_title,
                            item_brand: 'Neoserv',
                            item_category: dataObject.type,
                            item_category2: dataObject.sub_type,
                            quantity: 1
                        }
                    ]
                };
                break;
            case 'SentApplication':
                eventSlug = 'form_submit';
                eventData = dataObject;
                break;
            case 'SentForm':
                eventSlug = 'form_submit';
                eventData = dataObject;
                break;
            default:
                eventSlug = eventObject.eventAction;
            // code block
        }

        // Send event to google analytics
        this.analytics('event', eventSlug, eventData);
    },

    trackSearchPiwik: function (eventSearch) {
        var piwikArray = [],
            searchCount = parseInt(eventSearch.resultsCount);

        if (searchCount == 'NaN') {
            searchCount = false;
        }

        if (this.piwikAvailable()) {
            piwikArray = [
                'trackSiteSearch',
                eventSearch.term,
                eventSearch.category,
                searchCount
            ];
            this.piwik.push(piwikArray);
        }
    },

    /**
     * @param eventSearch { term, category }
     */
    trackSearch: function (eventSearch) {
        // Send to google analytics if available
        if (this.analyticsAvailable()) {
            this.analytics('event', 'search', {search_term: eventSearch.term, category: eventSearch.category});
        }
        // Send to facebook if available
        if (this.facebookAvailable()) {
            var eventData = { event: 'Search' };

            if (eventSearch.eventId) {
                eventData.eventID = eventSearch.eventId;
            }
            this.facebook('track', 'Search', {search_string: eventSearch.term}, eventData);
        }
    },

    analyticsAvailable: function () {
        Debug.log('Tracking.analyticsAvailable');
        if (typeof window.gtag !== 'undefined') {
            Debug.log('Tracking.analyticsAvailable: window.gtag exists');
            this.analytics = window.gtag;
            return true;
        }
        return false;
    },

    facebookAvailable: function () {
        Debug.log('Tracking.facebookAvailable');
        if (typeof window.fbq !== 'undefined') {
            this.facebook = window.fbq;
            return true;
        }
        return false;
    },

    piwikAvailable: function () {
        if (typeof window._paq !== 'undefined') {
            this.piwik = window._paq;
            return true;
        }
        return false;
    },

    orientationChange: function () {
        Debug.log('Tracking.orientationChange');
        var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        var i = w > h ? 'Landscape' : 'Portrait';
        window.addEventListener("resize", function () {
            var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
            var o = w > h ? 'Landscape' : 'Portrait';
            if (o != i) {
                Tracking.track({
                    'event': 'orientation',
                    'eventCategory': 'Device Orientation',
                    'eventAction': 'Orientation Change',
                    'eventLabel': 'Switch to ' + o
                });
                i = o;
                /**
                 * @todo popravi - trenutno tipkovnica sproži reload
                 */
                //location.reload();
            }
        }, false);
    }
};
