var Contact = {


	submit: function(e) {
		e.preventDefault();

		var b = $(this);
		var form = b.closest('form');
        var serialziedForm = form.serializeArray();

        if (form.find('[name="captcha"]').val() != 2) {
            return false;
        }

        $.each(serialziedForm, function(index, value) {
            if (typeof value.name != 'undefined' && value.name == 'message') {
                value.value = value.value.replace( /\r\n/g, '<br \\>' );
            }
        });

        var data = $.param(serialziedForm);

		b.addClass('btn-loading');

        form.parent().find('.ok').hide();
        form.parent().find('.generalerror').hide();
        form.parent().find('.invalid-email-error').hide();

        var formDetails = {form_id: form.attr('id'), form_name: form.attr('name'), form_destination: Hlp.getApi() + '/contact', form_submit_text: b.text()};

		$.ajax({
			url: Hlp.getApi() + '/contact',
			type: 'POST',
			data: data,
			success: function(data) {
                Tracking.track({ eventCategory: 'Contact', eventAction: 'SentForm', eventLabel: 'ContactSuccess'}, formDetails);
				b.removeClass('btn-loading');
				$("textarea", form).val('');
				$("input", form).parent().addClass('notice-success');
				form.fadeOut();
				form.parent().find('.ok').removeClass('hidden').fadeIn();
			},
			error : function(xhr) {
                //Tracking.track({ eventCategory: 'Contact', eventAction: 'SentForm', eventLabel: 'ContactFailed'}, formDetails);
			    if (xhr.status === 503) {
                    b.removeClass('btn-loading');
                    form.parent().find('.invalid-email-error').removeClass('hidden').fadeIn();
                } else if (xhr.status === 422) {
					//reset first
					b.removeClass('btn-loading');
					$('.notice-text:not(.static)', form).remove();
					$('.notice-error', form).removeClass('notice-error');
					$('.notice', form).fadeOut();

					var err = xhr.responseJSON.errors;
					$.each(err, function(key, errors) {
						var holder = $('.notice[data-key="' + key + '"]', form);

						if (holder.length) {
							$.each(errors, function(i, error) {
								var html = $('<strong class="notice-text"><div class="error">' + error + '</div></strong>');
								holder.append(html).hide().removeClass('hidden').fadeIn();
								holder.parent().addClass('notice-error');
							});
						}
					});
				}  else {
					b.removeClass('btn-loading');
					form.parent().find('.generalerror').removeClass('hidden').fadeIn();
				}
			}
		});

	}

};
