/*jslint browser: true*/
/*global $, jQuery, alert*/

var Wordpress = {

	init: function () {
		/* WORDPRESS zadeve */
		var $searchField = $('.search-field'),
			$searchBox = $('.search-box');

		$('.js-search-trigger').on('click', function (event) {
			event.preventDefault();
            $(document.body).addClass('u-overflow-hidden');
			$searchBox.toggle('size',{ origin: ['top', 'right'] });
			$searchField.focus();
		});
		$('.close-search').on('click', function (event) {
			event.preventDefault();
            $(document.body).removeClass('u-overflow-hidden');
			$searchBox.removeClass('results').toggle('size', { origin: ['top', 'right'] });
			$searchField.val('');
		});

		// Podpora - search
		$('.search-form').on('keyup focus submit', Wordpress.search);

		$(document.body).on('click', '.results-title .icon-close', Wordpress.searchClose);
		// TODO - zapri ob izgubi fokusa

		// Komentarji
		$('.reply').on('click', Wordpress.commentReply);
		$('#comment-edit').on('click', '.replying-to .icon-close', Wordpress.commentReplyRemove);

		// Email guides
		$('.hardware-pick').on('click', function (event) {
			//event.preventDefault();
			var $this = $(this);
			$('.hardware-pick').removeClass('active');
			$this.addClass('active').closest('.inline-list').addClass('selected');
            //Hlp.conditionalDisplay();
            Wordpress.guideFind();
        });

		$('.guides-selection select').on('change', Wordpress.guideFind);

		// If email-settings class is present, trigger guide find action
		if ($('.email-settings')) {
			$('.guides-selection select').trigger('change');
		}

		$('.js-guide-hw-selector').on('click', function(event) {
            var $this = $(this);
            var $target = $($this.data('display-target')),
                $hideTarget = $($this.data('hide-target'));

            $hideTarget.addClass('hidden');
            $target.removeClass('hidden');
        });

		// Moving through search results using arrow keys
		$(document).on('keydown keypress', function(event) {
			var $focused,
				$resultElements,
				$searchResults,
				nextElement;

			// if not up / down arrow
			if (event.which !== 40 && event.which !== 38) {
				return true;
			}

			// If pages doesn't contain search results
			$searchResults = $('.search-results');
			if (!$searchResults.length || !$searchResults.is(':visible') ) {
				return true;
			}

			event.preventDefault();
			$focused = $searchResults.find('.result.focused');

			// result is already focused
			if ($focused.length){
				$resultElements = $('.search-results .result');
				if (event.which === 40) {
					if ($resultElements.last().hasClass('focused')) {
						// Last result is focused & next result is requested
						return true;
					}
					nextElement = $focused.removeClass('focused').next('.result');
				} else if (event.which === 38) {
					if ($resultElements.first().hasClass('focused')) {
						// First result is focused & previous result is requested
						return true;
					}
					nextElement = $focused.removeClass('focused').prev('.result');
				}
			} else if ($('.search-field').is(':focus')) {
				// input is focused
				$focused = $(':focus');
				nextElement = $focused.closest('.search-box').find('.result').removeClass('focused').first();
			}
			if (typeof nextElement !== 'undefined' && nextElement.length) {
				nextElement.addClass('focused').children('a').focus();
			}
		});

	},

	search: function (event) {
		event.preventDefault();
		var $this = $(this),
			target = $this.data('target'),
			$target = $(target),
			serialized,
			searchResponse,
			blacklistedCodes = [40, 38]; //up, down

		serialized = $this.serialize();

		// If form is still loading and user doesn't confirm it with enter
		if (($this.hasClass('loading') || serialized === 'term=') && event.keyCode !== 13) {
			return false;
		}
		// If this is blacklisted key
		if (blacklistedCodes.indexOf(event.keyCode) !== -1) {
			return false;
		}

		searchResponse = Wordpress.ajaxSearchRequest($this, serialized);
	},

	ajaxSearchRequest: function($this,serialized) {
		$this.addClass('loading');
		return $.ajax({
			'url' :  $this.attr('action'),
			'data' : serialized,
			'success' : function (data) {
				var searchTerm,
					target =  $this.data('target'),
					$target = $(target);

				if (data.html) {
					$this.closest('.search-box').addClass('results');
					$target.replaceWith(data.html);
				}
				$this.removeClass('loading');
				searchTerm = $this.find('input.search-field').val();
				if (searchTerm !== data.term) {
					setTimeout(function() {
						serialized = $this.serialize();
						Wordpress.ajaxSearchRequest($this, serialized);
					}, 150);
				} else if (data.success) {
					Tracking.trackSearchPiwik({'term': searchTerm, 'category': 'search', 'resultsCount': data.count});
                    //Tracking.trackSearch({'term': searchTerm });
				}
			},
			'error' : function (data) {
				$this.removeClass('loading');
		},
			'type' : 'GET',
			'dataType' : 'json'
		});
	},

	searchClose: function (event) {
		event.preventDefault();
		$('.search-results').addClass('hidden');
	},

	handleComment: function(event, data) {
		// @TODO Add loading
		if (typeof data.responseJSON.errors !== 'undefined') {
			var placeholderNotice = $('.notice-placeholder'),
				warningText = placeholderNotice.find('.warning');
			$.each(data.responseJSON.errors, function(targetInput, error) {
				var target = $("[name='"+targetInput+"']");
				var targetParent = target.closest('.input-content');
				targetParent.find('.notice').remove();
				warningText.html(error);
				targetParent.append(placeholderNotice.clone().removeClass('hidden notice-placeholder'));
			});
		} else {
			$('#comment-form').hide();
			$('.comment-success').removeClass('hidden');
		}



		// @TODO display success message

	},

	commentReply: function (event) {
		var $this = $(this),
			comment = $this.closest('.comment'),
			commentHtml = comment.html(),
			closeHtml = '<a role="button" class="icon-close"></a>',
			commentId = 0;

		commentId = $this.closest('.std-list.primary').find('.comment').first().data('id');

		$('.replying-to > .comment').html(commentHtml).append(closeHtml).children('.std-list').remove();
		$('.replying-to').removeClass('hidden').show();
		$('#comment-parent').val(commentId);
	},

	commentReplyRemove: function (event) {
        $('.replying-to').hide('slow');
        $('#comment-parent').val(0);
	},




	guideFind: function (event) {
	    Debug.log('Wordpress.guideFind');

		var searchParams = {
			hardwareCategory : {
				value : null,
				required : true,
				selector: '$hardwarePick'
			},
			osCategory :  {
				value : null,
				required : true,
				selector: '.os-select'
			},
			softwareTag :  {
				value : null,
				required : false,
				selector: '.software-select'
			},
			langTag :  {
				value : null,
				required : false,
				selector: '.lang-select'
			},
		};

        var $hardwarePick = $('.hardware-pick.active'),
			$resultsTarget = $('.guides-results');

        // Get parameters from URL
        var urlParameters = Hlp.getURLParameters(),
            $hardwareCategory = null;


		// Glavni parameter, preko katerega dobimo ostale
		searchParams.hardwareCategory.value = $hardwarePick.data('guide-hardware');

		// Zadeve še loadajo...
		if ($resultsTarget.isLoading()) {
			//return false;
		}

		// Gather all available values
		var $osSelect = $("."+ searchParams.hardwareCategory.value +" .os-select"),
			$langSelect = $("."+ searchParams.hardwareCategory.value +" .lang-select"),
			$softwareSelect = $("."+ searchParams.hardwareCategory.value +" .software-select:not(.hidden)"),
			flatParameters = {},
			error = false;


		// Zberi vse potrebne spremenljivke
		searchParams.osCategory.value = $osSelect.val();
		searchParams.langTag.value = $langSelect.val();
		searchParams.softwareTag.value = $softwareSelect.val();

		// Določi strojno kategorijo iz URLja
		if (typeof urlParameters.hardwareCategory !== 'undefined' || urlParameters.hardwareCategory !== null) {
			$hardwareCategory = $("[data-guide-hardware='"+ urlParameters.hardwareCategory +"']");
			// "Aktviriaj" element
			//$hardwareCategory.addClass('active').trigger( "click" ).closest('.inline-list').addClass('selected');
		}

		// Preveri, če so vse spremenljivke nastavljene
		$.each(searchParams, function(index, val) {

			if (typeof val.value !== 'undefined' && val.value !== null && val.value !== '') {
				// Value je določen preko elementa
				flatParameters[index] = val.value;
				return true;
			} else if (typeof urlParameters[index] != 'undefined' && urlParameters[index] != null) { /* Do not change loose comparison*/
				// Value je določen preko URLja
				flatParameters[index] = urlParameters[index];
				// Prikaži izbran select/option
				$hardwareCategory.find("[data-cond-value='"+ urlParameters[index] +"']").removeClass('hidden');
				return true;
			} else if (val.required) {
				// Value je zahtevan, a ni določen
                Debug.log("error", val);
				error = true;
				return true;
			}
			flatParameters[index] = val.value;
		});



		// Manjkajoča polja
        if (error) {
            Debug.log("WP error:", error);
            return false;
        }

		Hlp.appendParametersToUrl(flatParameters);


		// Prikaži loader samo, če že obstajajo rezultati
		if ($resultsTarget.height() > 75) {
			var options = { top: 0, left: 0 };
			$resultsTarget.startLoading(options);
		}

		$.ajax({
			url: '/podpora/nastavitve-poste',
			data: flatParameters,
			success: function(data) {
			    Debug.log("Ajax results:", data);
				if (data) {
					if (data.target) {
						$(data.target).replaceWith(data.html);
                        Tracking.trackSearchPiwik({'term': JSON.stringify(flatParameters), 'category': 'search-guides', 'resultsCount': data.count});
                        //Tracking.trackSearch({'term': JSON.stringify(flatParameters) });
					}
				}
				$resultsTarget.stopLoading();
			},
            error: function(data) {
                Debug.log("Ajax error results:", data);
            }
		});

	}



};

var tab;
var tabContent;
var tabsBlog = document.getElementById('tabsBlog');

window.onload =function() {
    tabContent=document.getElementsByClassName('tabContent');
    tab=document.getElementsByClassName('tab');
    hideTabsContent(1);
};

if (tabsBlog) {
    tabsBlog.onclick = function (event) {
        var target = event.target;
        if (target.className == 'tab') {
            for (var i = 0; i < tab.length; i++) {
                if (target == tab[i]) {
                    showTabsContent(i);
                    break;
                }
            }
        }
    };
}

function hideTabsContent(a) {
    for (var i=a; i<tabContent.length; i++) {
        tabContent[i].classList.remove('show');
        tabContent[i].classList.add("hide");
        tab[i].classList.remove('whiteborder');
    }
}

function showTabsContent(b){
    if (tabContent[b].classList.contains('hide')) {
        hideTabsContent(0);
        tab[b].classList.add('whiteborder');
        tabContent[b].classList.remove('hide');
        tabContent[b].classList.add('show');
    }
}
