// const { css } = require("jquery");

//hosting landing
$(document).ready(function() {
    if($('.bg-gostovanje').length) {
        $('.benefits li').eq(0).delay(1000).fadeOut().fadeIn(200);
        $('.benefits li').eq(1).delay(2000).fadeOut().fadeIn(200);
        $('.benefits li').eq(2).delay(3000).fadeOut().fadeIn(200);
        $('.benefits li').eq(3).delay(4000).fadeOut().fadeIn(200);
    }
});

//vps landing
$(document).ready(function() {
    if($('.bg-vps').length) {
        $('.bg-vps .advantages-list ul li').eq(0).delay(1000).fadeOut().fadeIn(200);
        $('.bg-vps .advantages-list ul li').eq(1).delay(2000).fadeOut().fadeIn(200);
        $('.bg-vps .advantages-list ul li').eq(2).delay(3000).fadeOut().fadeIn(200);
        $('.bg-vps .advantages-list ul li').eq(3).delay(4000).fadeOut().fadeIn(200);
        $('.bg-vps .advantages-list ul li').eq(4).delay(5000).fadeOut().fadeIn(200);
    }
});

// Turbo hosting
$(document).ready(function(){
	var starElements = $(".shooting_star");
	for(var countStar=1; countStar<=starElements.length; countStar++){
		var delayStar=Math.floor((Math.random() * 9999) + 1);
		var top=Math.floor((Math.random() * 650) + 1);
		var left=Math.floor((Math.random() * 300) + 1);
		$(".shooting_star:nth-child("+countStar+")").css({
			//"top": "calc(100% - " + top + "px - 200px)",
			"top": top,
			"left": "calc(0% - " + left + "px)",
			"animation-delay": delayStar + "ms"
		});
		$(".shooting_star:nth-child("+countStar+")::before").css({
			"animation-delay": delayStar + "ms",
			"-webkit-animation-delay": delayStar + "ms"
		});
		$(".shooting_star:nth-child("+countStar+")::after").css({
			"animation-delay": delayStar + "ms",
			"-webkit-animation-delay": delayStar + "ms"
		});
	}

	var ledLights = $(".server polygon");
	for(var countLights=1; countLights<=ledLights.length; countLights++){
		var delayLights=Math.floor((Math.random() * 500) + 1);
		$(".server polygon:nth-child(" + countLights + ")").css({
			"animation-delay": delayLights + "ms"
		});
	}
});
// Reseller hosting
$(document).ready(function(){
	var advantage1 = $("#nvme");
	var advantage2 = $("#safe");
	var advantage3 = $("#cpanel");
	var advantage4 = $("#mariadb");
	var advantage5 = $("#litespeed");
	setTimeout(
		function()
		{
			advantage1.css("opacity", "1");
		}, 450);
	setTimeout(
		function()
		{
			advantage2.css("opacity", "1");
		}, 150);
	setTimeout(
		function()
		{
			advantage3.css("opacity", "1");
		}, 550);
	setTimeout(
		function()
		{
			advantage4.css("opacity", "1");
		}, 850);
	setTimeout(
		function()
		{
			advantage5.css("opacity", "1");
		}, 350);

	setTimeout(
		function()
		{
			$(".kv-list-title").css("opacity","1");
		}, 1000
	);

	// list animation
	setTimeout(
		function()
		{
			$(".kv-reseller-list li:first-child").css("opacity","1");
		}, 1600
	);
	setTimeout(
		function()
		{
			$(".kv-reseller-list li:nth-child(2)").css("opacity","1");
		}, 2100
	);
	setTimeout(
		function()
		{
			$(".kv-reseller-list li:nth-child(3)").css("opacity","1");
		}, 2600
	);
	setTimeout(
		function()
		{
			$(".kv-reseller-list li:last-child").css("opacity","1");
		}, 3100
	);

});

// Dedicated servers
$(document).ready(function(){
	var ledLights = $("#workingLights path");
	$(".buttonPlay").on("click",function(){
		$("#puscica").fadeOut();
		$(".gumbTop").css("transform", "translateY(10px)");
		setTimeout(function(){
			$(".gumbTop").css("transform", "translateY(0px)");
		}, 100);
		setTimeout(function(){
			$("#power-on").fadeIn();
		}, 200);
		setTimeout(function(){
			$(".bg-dedicated").addClass("play");
			$(".buttonPlay").fadeOut();
			$("#andyStart").fadeOut();
			$("#andyEnd").fadeIn();
			for(var countLights=1; countLights<=ledLights.length; countLights++){
				var delayLights=Math.floor((Math.random() * 500) + 1);
				var durationLights=Math.floor((Math.random() * 3)+1);
				$("#workingLights path:nth-child(" + countLights + ")").css({
					"animation-delay": delayLights + "ms"
				});
				$("#workingLights path:nth-child(" + countLights + ")").css({
					"animation-duration": durationLights + "s"
				});
			}
		}, 200);
		var countList=1;
		setTimeout(function(){
			showList();
		}, 1000);

		function showList(){
			setTimeout(function(){
				$('.advantages-list ul li:nth-child(' + countList +')').fadeIn();
				countList++;
				if(countList<=$(".advantages-list ul li").length){
					showList();
				}
			}, 600);
		}

	});
});
