
var MiniCart = {

    init: function () {
        Debug.log('MiniCart.init');

        $(document).on('click', '.js-cart-top', MiniCart.toggle);

        // Ločena funkcija za '.js-closeminicart' - odstrani še '.hovered' class
        $(document.body).on('click', '.js-closeminicart', MiniCart.close);

        // $('.js-closeminicart').on('click', Toggle.active);
        $('.js-minicart-holder').on('click', '.js-minicart-more', MiniCart.miniMore);

        // Prenos triggerja iz mobile-cart-zone - meni je v drugi div skupini
        $(document.body).on('mouseenter', '.js-mobile-cart-zone', function () {
            $(document.body).find('.js-minicart').addClass('hovered');
        });
        $('.js-minicart').on('mouseleave', function () {
            var $jsCartContent = $('.js-cart-content:hover');

            if ($jsCartContent.length === 0) {
                $(this).removeClass('hovered');
            }
        });

        $('.js-minicart-nocart').on('click', this.focusOnCartContent);
    },


    /**
     * When user clicks on disabled mini-cart (nocart), scroll to main cart's title/content
     */
    focusOnCartContent: function () {
        Debug.log('MiniCart.focusOnCartContent');
        $('.js-cart-title').velocity('scroll', {
            duration: 300,
            offset: -60,
            easing: 'ease-in-out'
        });
    },


    /**
     * Toggles display of more than 5 items in mini-cart list
     * @param  {object} event
     */
    miniMore: function (event) {
        Debug.log('MiniCart.miniMore');
        event.preventDefault();
        var $this = $(this),
            $cart = $(this).parents('.js-minicart-list');

        $('.item.hidden', $cart).hide().removeClass('hidden').slideDown();
        $this.closest('.collapsed').hide();
    },


    /**
     * Toggles mini-cart state
     */
    toggle: function () {
        Debug.log('MiniCart.toggle');
        var $miniCart = $('.js-minicart');
        $miniCart.toggleClass('active');

        if ($miniCart.hasClass('active')) {
            Menu.closeMobileMenu();
            MiniCart.closeOnOutsideClick();
        }
    },


    /**
     * Closes mini-cart when click outside of mini-cart happens
     */
    closeOnOutsideClick: function ()
    {
        $(document).on('click.cartTrigger', function (bodyEvent) {
            Debug.log('MiniCart.closeOnOutsideClick');
            var $this = $(this);
            if ($(bodyEvent.target).closest('.js-minicart').length === 0) {
                MiniCart.close();
                $(document.body).unbind(bodyEvent);
            }
        });
    },


    /**
     * Closes mini-cart
     */
    close: function ()
    {
        Debug.log('Cart closing');
        var $miniCart = $('.js-minicart');
        $miniCart.removeClass('hovered active');

    },


    /**
     * Changes number displaying quantity of items in mini-cart
     */
    changeQuantity: function (cartQty)
    {
        Debug.log('MiniCart.changeQuantity');
        $('.mini-cart .cart-qty').text(cartQty);
    }
};
