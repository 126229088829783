
var Ajax = {
    init: function() {
        $(document.body).on('click', '.js-ajax-change', Ajax.view);
        $('.js-ajax-form').on('submit', Ajax.submitform);
        $(document.body).on('click', '.js-ajax-request', Ajax.request);
    },

	request: function(event) {
        Debug.log('Ajax.request');

		event.preventDefault();
		var el = $(this);
		var url;
		if(this.nodeName === 'A') {
			url = el.attr('href');
		} else {
			url = el.data('href');
		}
		if(url === '')return false;
		$.ajax({
			url: url,
			success: function(data) {
				if(el.data('norefresh') != '1') {
					location.reload();
				}
			},
			error : function(xhr) {
				return false;
			}
		});
	},

	view: function(event) {
        Debug.log('Ajax.view');

        event.preventDefault();
		var $this = $(this);
		var pathname = window.location.pathname;
		if (typeof $this.attr('href') !== 'undefined' && $this.attr('href') !== '') {
			pathname = $this.attr('href');
		}
        var originalURL = window.document.URL.toString().split("#");
        var parameters = Hlp.getURLParameters(null, originalURL[0]);
        var name = null;
        var value = $this.attr('data-val') ? $this.data('val') : $this.val();

        if (!$this.attr('name')) {
            name = $this.closest('[name]').attr('name');
        } else {
            name = $this.attr('name');
        }

        if (typeof parameters === 'undefined') {
            /* Trenutno še ni querya */
            parameters = {};
        }
        if (parameters[name] !== value) {
            /* Če nov value ni enak prejšnemu */
            if (value) {
                parameters[name] = value;
            } else {
                delete parameters[name];
            }
        } else {
            /* Nov value je enak prejšnemu */
            return false;
        }
        var url = pathname;
        var url_params = Hlp.getURLParameters(null, url);
        $.each(url_params, function(i, urlParameter){
            delete parameters[i];
        });

        //preveri ce je treba odstranit kaksen parameter
        if($this.data('remove-key') && $this.data('remove-key') !== '') {
            delete parameters[$this.data('remove-key')];
        }

        if(!$.isEmptyObject(parameters)) {
            var separator = (url.indexOf('?') === -1) ? '?' : '&';
            url += separator + $.param(parameters);
        }

        // Dodaj v history - check za IE9
        if (typeof history.pushState == 'function') {
            if (typeof url[1] !== 'undefined' && typeof originalURL[1] !== 'undefined') {
                url += "#" + originalURL[1];
            }
            history.pushState(null, null, url);
        }
		var sourceElement = $this.closest('[data-source]');
		parameters.type = sourceElement.data('type');
		parameters.source = sourceElement.data('source');
		parameters.sourceID = sourceElement.attr('id');
		sourceElement.startLoading();

		$.ajax({
			url: pathname,
			data: parameters,
			success: function(data) {
				sourceElement.stopLoading();
				if (data.data) {
					if (data.data.targets) {
						$.each(data.data.targets, function(index, val) {
							$('#'+index).replaceWith(val);
						});
						Triggers.inputHandlers();
						//poklici callback ce obstaja
						if(sourceElement.length && sourceElement.data('callback') && sourceElement.data('callback') !== '') {
							var callbackFunction = Utility.callByStringNested(sourceElement.data('callback'));
							if (typeof callbackFunction === "function") {
								callbackFunction.apply(this, [data]);
							}
						}
					}
				}
			},
			error : function(data) {
				sourceElement.stopLoading();
			}
		});
	},

	submitform: function(event) {
	    Debug.log('Ajax.submitform');

		event.preventDefault();
		var $this = $(this);

		$.ajax({
			'url' :  $this.attr('action'),
			'data' : $this.serialize(),
			'type' : 'POST',
			'dataType' : 'json',
			complete : function(data){
				var callback = $this.data('callback');

				if (typeof callback !== 'undefined' && callback !== '') {
					Utility.callByStringNested(callback, $this, data);
					var callbackFunction = Utility.callByStringNested(callback);
					if (typeof callbackFunction === "function") {
						callbackFunction.apply(this, [event, data]);
					}
				}
			}
		});
	},
};

